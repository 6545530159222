import {api} from "../../../api/api";
import {objectToUrl} from "../../../utils/helpers";

export function getServices(filter) {
    return api.get("services/list" + objectToUrl(filter)).then(res => {
        return res && res.payload
    });
}

export function getService(id){
    return  api.get("services?serviceId=" + id).then(res => {
        return res && res.payload
    })
}

export function addService(data){
    return api.post("services/create", data).then(res=> {
        return res && res.payload
    })
}

export function editService(data){
    return api.put("services", data).then(res=> {
        return res && res.payload
    })
}
export function deleteService(id) {
    return api.delete("services/" + id).then(res => {
        return res && res.payload
    });

}

export function deleteEtService(id) {
    return api.delete("services/delete/etc_service/" + id).then(res => {
        return res && res.payload
    });

}
export function getEtcServices(filter) {
    return api.get("services/list/etc_service" + objectToUrl(filter)).then(res => {
        return res && res.payload
    });
}