import React from "react";
import {routes} from "../../routes/routes";
import {Redirect, Route, useLocation} from "react-router-dom";
import MainContainer from "../../containers/MainContainer";
import {ToastContainer} from "react-toastify";
import {useSelector} from "react-redux";

const Main = () => {
    const location = useLocation();
    const token = useSelector(state => state.main.token)
    if(!Boolean(token) && location.pathname.slice(1) !== "login"){
        return <Redirect to="/login" from={location.pathname}/>
    }
    if(Boolean(token) && location.pathname.slice(1) === "login"){
        return <Redirect to="/" from={location.pathname}/>
    }

    return (
        <>
            <MainContainer isAuthed={token}>
                {routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    );
                })}
            </MainContainer>
            <ToastContainer/>
        </>
    )
}
export default Main