import {NavLink, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {toast} from "react-toastify";
import {api} from "../../../api/api";
import Title from "../../title/Title";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect} from "../../../utils/helpers";

export default function AddTender(props) {
    const history = useHistory();
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        companyName: BRANDS.tps
    });
    const [data, setData] = useState(
        {
            applicationDeadline: "",
            applicationStartDate: "",
            companyName: filter.companyName.toLocaleUpperCase(),
            customer: "",
            deliveryTime: "",
            email: "",
            lang: filter.lang.toLocaleUpperCase(),
            lotDescription: "",
            manager: "",
            quantity: "",
            measurementUnit: "",
            organizer: "",
            phoneNumber: "",
            attachments: "",
            publicationDate: "",
            purchaseMethod: "",
            purchaseName: "",
            requestNumber: "",
            termsOfDelivery: ""
        }
    );

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            lang: filter.lang.toLocaleUpperCase(),
            companyName: filter.companyName.toLocaleUpperCase()
        }))
    }, [filter]);
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.applicationDeadline) &&
            Boolean(data.applicationStartDate) &&
            Boolean(data.companyName) &&
            Boolean(data.customer) &&
            Boolean(data.deliveryTime) &&
            Boolean(data.email) &&
            Boolean(data.lang) &&
            Boolean(data.lotDescription) &&
            Boolean(data.manager) &&
            Boolean(data.measurementUnit) &&
            Boolean(data.organizer) &&
            Boolean(data.phoneNumber) &&
            Boolean(data.publicationDate) &&
            Boolean(data.purchaseMethod) &&
            Boolean(data.purchaseName) &&
            Boolean(data.requestNumber) &&
            Boolean(data.attachments) &&
            Boolean(data.quantity) &&
            Boolean(data.termsOfDelivery)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.post("tender/create", {...data, lang: filter.lang.toLocaleUpperCase()})
                .then(() => {
                    history.push("/tender");
                    toast.success("Успешно добавлено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="tender_language"
                                    extraClassForSelect="mini"
                                    label="Language"
                                    values={objectToArrayForSelect(LANG_UPPER)}
                                    filterName="lang"
                                    activeValue={filter.lang}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="tender_brand"
                                    extraClassForSelect="mini"
                                    label="Company"
                                    values={objectToArrayForSelect(BRANDS)}
                                    filterName="companyName"
                                    activeValue={filter.companyName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.requestNumber}
                                placeholder="Номер заявки"
                                onChange={(e) =>
                                    handleChange("requestNumber", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.purchaseName}
                                placeholder="Название покупки"
                                onChange={(e) =>
                                    handleChange("purchaseName", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.purchaseMethod}
                                placeholder="Способ покупки"
                                onChange={(e) =>
                                    handleChange("purchaseMethod", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.customer}
                                placeholder="Клиент"
                                onChange={(e) =>
                                    handleChange("customer", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.organizer}
                                placeholder="Организатор"
                                onChange={(e) =>
                                    handleChange("organizer", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.publicationDate}
                                placeholder="Дата публикации"
                                onChange={(e) =>
                                    handleChange("publicationDate", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.applicationStartDate}
                                placeholder="Дата начала подачи заявки"
                                onChange={(e) =>
                                    handleChange("applicationStartDate", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.applicationDeadline}
                                placeholder="Срок подачи заявок"
                                onChange={(e) =>
                                    handleChange("applicationDeadline", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.lotDescription}
                                placeholder="Описание лота"
                                onChange={(e) =>
                                    handleChange("lotDescription", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.quantity}
                                placeholder="Количество"
                                onChange={(e) =>
                                    handleChange("quantity", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.measurementUnit}
                                placeholder="Измерительное устройство"
                                onChange={(e) =>
                                    handleChange("measurementUnit", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.deliveryTime}
                                placeholder="Срок поставки"
                                onChange={(e) =>
                                    handleChange("deliveryTime", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.termsOfDelivery}
                                placeholder="Условия доставки"
                                onChange={(e) =>
                                    handleChange("termsOfDelivery", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.attachments}
                                placeholder="Вложения"
                                onChange={(e) =>
                                    handleChange("attachments", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.phoneNumber}
                                placeholder="Телефонный номер"
                                onChange={(e) =>
                                    handleChange("phoneNumber", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.manager}
                                placeholder="Менеджер"
                                onChange={(e) =>
                                    handleChange("manager", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.email}
                                placeholder="Электронное письмо"
                                onChange={(e) =>
                                    handleChange("email", e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3 col-md-12 d-flex justify-content-between mb-3">
                            <NavLink to="/tender" className="btn btn-outline-accent">
                                Отмена
                            </NavLink>
                            <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                Публиковать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
