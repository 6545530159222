import React, {useState} from 'react';
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {api} from "../../../api/api";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
function SupportCategoriesItem({id, title, link }) {

    const [deleted, setDeleted] = useState();

    const handleDelete = (id) => {
        api.delete("support/delete/" + id).then(res=>{
            if(res.status) {
                toast.success("Удалено");
                setDeleted(true);
            }
        })
    };
    return (
        <div className={`faq_item mb-4 py-2 px-4 bg-white rounded ${deleted && "deleted"}`}>
            <div className="d-flex justify-content-between align-items-center">
            <h4 className="faq_item__question m-0">{title}</h4>
            </div>
            <div className="faq_item__answer mb-2">
                <p className="m-0"><a href={link}>{link}</a></p>
            </div>
            <div className="my-auto ml-auto d-flex justify-content-end">
                <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"
                         to={"/support-categories/" + id}>
                    <FiEdit2 className="mr-1"/>
                    Edit
                </NavLink>
                <button onClick={()=> handleDelete(id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >
                    <AiOutlineDelete className="mr-1"/>
                    Delete
                </button>
            </div>
        </div>
    );
}

export default SupportCategoriesItem;