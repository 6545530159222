import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {NavLink, useHistory} from "react-router-dom";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {BRANDS} from "../../../constants/GLOBAL";
import {
    AiOutlineInstagram,
    AiOutlineFacebook,
    FaTelegram,
    AiOutlineTwitter,
    GrClose
} from "react-icons/all";
import {toast} from "react-toastify";
import {uploadFile} from "../../../constants/API";
import {api} from "../../../api/api";

function AddCompanySettings(props) {
    const history = useHistory();
    const [filter, setFilter] = useState({
        brandName: BRANDS.tps
    });

    const [logoDark, setLogoDark] = useState(null);
    const [logoLight, setLogoLight] = useState(null);
    const [liveFileDark, setLiveFileDark] = useState(null);
    const [liveFileLight, setLiveFileLight] = useState(null);
    const [formDataDark, setFormDataDark] = useState(null);
    const [formDataLight, setFormDataLight] = useState(null);
    const [validate, setValidate] = useState(false);
    const [data, setData] = useState(
        {
            phoneNumber: "",
            logoWhite: "",
            logoDark: "",
            brandName: filter.brandName,
            facebook: "",
            telegram: "",
            twitter: "",
            instagram: ""
        }
    );

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", logoDark);
        setFormDataDark(newFormData)
    }, [logoDark]);

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", logoLight);
        setFormDataLight(newFormData)
    }, [logoLight]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.phoneNumber) &&
            Boolean(data.brandName)
        )) {
            setValidate(true);
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setValidate(false);
            if(logoDark){
                uploadFile(formDataDark).then(res => {
                    uploadFile(formDataLight).then(r => {
                        let newData = {
                            brandName: filter.brandName,
                            phoneNumber: data.phoneNumber,
                            socials: {
                                instagram: data.instagram,
                                facebook: data.facebook,
                                twitter: data.twitter,
                                telegram: data.telegram,
                            },
                            logoDark: res.url,
                            logoWhite: r.url
                        };
                        api.post("settings/set", newData)
                            .then(res => {
                                history.push("/company-settings");
                                toast.success("Успешно добавлено")
                            })
                            .catch(error => {
                                toast.error(error.message, {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            })
                    }).catch(() =>{
                        toast.error("При загрузке изображение произошла ошибка")
                    });
                }).catch(() =>{
                    toast.error("При загрузке изображение произошла ошибка")
                })
            }else{
                let newData = {
                    brandName: filter.brandName,
                    phoneNumber: data.phoneNumber,
                    socials: {
                        instagram: data.instagram,
                        facebook: data.facebook,
                        twitter: data.twitter,
                        telegram: data.telegram,
                    },
                    logoDark: "",
                    logoWhite:""
                };
                api.post("settings/set", newData)
                    .then(res => {
                        history.push("/company-setting");
                        toast.success("Успешно добавлено")
                    })
                    .catch(error => {
                        toast.error(error.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
            }
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="tender_brand"
                                    extraClassForSelect="mini"
                                    label="Company"
                                    values={objectToArrayForSelect(BRANDS)}
                                    filterName="brandName"
                                    activeValue={filter.brandName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    {validate && (
                        <div className="p-3 mb-4">
                            <div className="text-danger">* Телефон номер</div>
                            <div className="text-danger">* Имя компании</div>
                            должно быть заполнено.
                        </div>
                    )}
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.phoneNumber}
                                placeholder="Телефон номер"
                                onChange={(e) =>
                                    handleChange("phoneNumber", e.target.value)}
                            />
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text text-primary"
                                          id="basic-addon1"><AiOutlineInstagram/></span>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={data.instagram}
                                    placeholder="http://instagram.com/user-name"
                                    onChange={(e) =>
                                        handleChange("instagram", e.target.value)}
                                />

                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text text-primary"
                                          id="basic-addon1"><FaTelegram/></span>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={data.telegram}
                                    placeholder="https://t.me/user-name"
                                    onChange={(e) =>
                                        handleChange("telegram", e.target.value)}
                                />

                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text text-primary"
                                          id="basic-addon1"><AiOutlineTwitter/></span>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={data.twitter}
                                    placeholder="https://twitter.com/user-name"
                                    onChange={(e) =>
                                        handleChange("twitter", e.target.value)}
                                />

                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text text-primary"
                                          id="basic-addon1"><AiOutlineFacebook/></span>
                                </div>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={data.facebook}
                                    placeholder="https://www.facebook.com/user-name"
                                    onChange={(e) =>
                                        handleChange("facebook", e.target.value)}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="card card-small mt-3 mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Загрузить фото</h6>
                        </div>
                        <div className="card-body">
                            {liveFileDark && (
                                <div className="mb-4">
                                    <div className="img-overlay d-inline-block">
                                    <img src={liveFileDark} alt=""/>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex">
                                <div>
                                    <input
                                        className="form-control form-control-lg mb-3 d-none"
                                        type="file"
                                        id="company_settings_logo__input_dark"
                                        accept=".jpg , .png"
                                        onChange={(e) => {
                                            setLogoDark(e.target.files[0]);
                                            setLiveFileDark(URL.createObjectURL(e.target.files[0]))
                                        }}
                                    />
                                    <h5 className="mb-1">Тёмный логотип:</h5>
                                    <label
                                        htmlFor="company_settings_logo__input_dark"
                                        className={"tariff_logo__label " + (logoDark ? "bg-success" : "bg-primary")}
                                    >
                                        {logoDark ? logoDark?.name : "Выберите изображение"}
                                    </label>
                                </div>
                                {logoDark &&
                                <button onClick={() => setLogoDark(null)} className="btn btn-danger p-2 file-remove-btn">
                                    <GrClose/></button>}
                            </div>

                            {logoDark && <div>
                                <p className="mb-2">
                                    Размер: <span className="text-primary">{(logoDark?.size / 1e+6).toFixed(2)} MB </span>
                                </p>
                            </div>}
                        </div>


                        <div className="card-body">
                            {liveFileLight && (
                                <div className="mb-4">
                                    <div className="img-overlay d-inline-block">
                                    <img src={liveFileLight} alt=""/>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex">
                                <div>
                                    <input
                                        className="form-control form-control-lg mb-3 d-none"
                                        type="file"
                                        id="company_settings_logo__input_light"
                                        accept=".jpg , .png"
                                        onChange={(e) => {
                                            setLogoLight(e.target.files[0]);
                                            setLiveFileLight(URL.createObjectURL(e.target.files[0]))
                                        }}
                                    />
                                    <h5 className="mb-1">Белый логотип:</h5>
                                    <label
                                        htmlFor="company_settings_logo__input_light"
                                        className={"tariff_logo__label " + (logoLight ? "bg-success" : "bg-primary")}
                                    >
                                        {logoLight ? logoLight?.name : "Выберите изображение"}
                                    </label>
                                </div>
                                {logoLight &&
                                <button onClick={() => setLogoLight(null)} className="btn btn-danger p-2 file-remove-btn">
                                    <GrClose/></button>}
                            </div>
                            <p className="mb-1 font-weight-normal">Максималыный размер изображения 5mb. <br/> Тип <b>.png,
                                .jpg</b> поддерживает.</p>
                            {logoLight && <div>
                                <p className="mb-2">
                                    Размер: <span className="text-primary">{(logoLight?.size / 1e+6).toFixed(2)} MB </span>
                                </p>
                            </div>}
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/company-settings" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                        Публиковать
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCompanySettings;