import React from "react";
import { Redirect } from "react-router-dom";
// Route Views
import NewsList from "../components/views/news/NewsList";
import CategoryList from "../components/views/newsCategory/CategoryList";
import AddNews from "../components/views/news/AddNews";
import EditNews from "../components/views/news/EditNews";
import TariffList from "../components/views/tariff/TariffList";
import Login from "../components/views/Login";

import AddTariff from "../components/views/tariff/AddTariff";
import ZoneCover from "../components/views/zone/ZoneCoverCities";
import FaqList from "../components/views/faq/FaqList";
import AddFaqs from "../components/views/faq/AddFaqs";
import ContactsList from "../components/views/contacts/ContactsList";
import AddContact from "../components/views/contacts/AddContact";
import VacancyList from "../components/views/vacancy/VacancyList";
import AddVacancy from "../components/views/vacancy/AddVacancy";
import EditVacancy from "../components/views/vacancy/EditVacancy";
import TenderList from "../components/views/tender/TenderList";
import AddTender from "../components/views/tender/AddTender";
import EditTender from "../components/views/tender/EditTender";
import Applications from "../components/views/applications/Applications";
import ApplicationsTechnique from "../components/views/applicationsTechnique/ApplicationsTechnique";
import PaymentList from "../components/views/payment/PaymentList";
import PaymentAddEdit from "../components/views/payment/PaymentAddEdit";
import ServiceList from "../components/views/services/ServiceList";
import ServiceAddEdit from "../components/views/services/ServiceAddEdit";
import PublicOfferList from "../components/views/publicOffer/PublicOfferList";
import AddPublicOffer from "../components/views/publicOffer/AddPublicOffer";
import { CompanySettingsList } from "../components/views/companySettings/companySettingsList";
import AddCompanySettings from "../components/views/companySettings/AddCompanySettings";
import { EditContacts } from "../components/views/contacts/EditContacts";
import { EditFaq } from "../components/views/faq/EditFaq";
import EditPublicOffer from "../components/views/publicOffer/EditPublicOffer";
import { RegisterUser } from "../components/views/registerUser/RegisterUser";
import { UpdateUser } from "../components/views/registerUser/UpdateUser";
import { EtServicesList } from "../components/views/EtPageServices/EtServicesList";
import { AddEtServices } from "../components/views/EtPageServices/AddEtServices";
import { EditEtServices } from "../components/views/EtPageServices/EditEtServices";
import SupportCategoriesList from "../components/views/support/SupportCategoriesList";
import { EditSupportCategories } from "../components/views/support/EditSupportCategories";
import AddSupportCategories from "../components/views/support/AddSupportCategories";
import CompanyRouterList from "../components/views/companyRouter/CompanyRouterList";
import CompanyRouterAdd from "../components/views/companyRouter/CompanyRouterAdd";
import CompanyRouterEdit from "../components/views/companyRouter/CompanyRouterEdit";
import Banners from "../components/views/banner/BannerList";
export const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/news/all" />,
  },
  {
    path: "/login",
    component: () => <Login />,
  },
  {
    path: "/news",
    exact: true,
    component: () => <Redirect to="/news/all" />,
  },
  {
    path: "/news/all",
    exact: true,
    component: () => <NewsList title="Все" base="Новости" />,
  },
  {
    path: "/news/item/:id",
    component: () => <EditNews base="Новости" />,
  },
  {
    path: "/news/add",
    component: () => <AddNews title="Добавить новое" base="Новости" />,
  },
  {
    path: "/news/cats",
    component: () => <CategoryList title="Категории" base="Новости" />,
  },
  {
    path: "/tariff",
    exact: true,
    component: () => <Redirect to="/tariff/all" />,
  },
  {
    path: "/tariff/all",
    exact: true,
    component: () => <TariffList title="Все" base="Тарифы" />,
  },
  {
    path: "/tariff/add",
    component: () => <AddTariff title="Добавить новое" base="Тарифы" />,
  },
  {
    path: "/tariff/item/:id",
    component: () => <AddTariff title="Изменить" base="Тарифы" />,
  },
  {
    path: "/zone",
    component: () => <ZoneCover title="Все" base="Зоны покрытие" />,
  },
  {
    path: "/faq",
    component: () => <FaqList title="Вcе" base="FAQ" />,
    exact: true,
  },
  {
    path: "/faq/add",
    component: () => <AddFaqs title="Добавить новое" base="Faqs" />,
  },
  {
    path: "/faq/item/:id",
    component: () => <EditFaq title="Изменить" base="Faqs" />,
  },
  {
    path: "/contacts",
    component: () => <ContactsList title="Вcе" base="Контакты" />,
    exact: true,
  },
  {
    path: "/contacts/add",
    component: () => <AddContact title="Добавить новое" base="Контакты" />,
  },
  {
    path: "/contacts/item/:id",
    component: () => <EditContacts title="Добавить новое" base="Контакты" />,
  },
  {
    path: "/vacancy",
    component: () => <VacancyList title="Вcе" base="Ваканции" />,
    exact: true,
  },
  {
    path: "/vacancy/add",
    component: () => <AddVacancy title="Добавить новую" base="Ваканцию" />,
  },
  {
    path: "/vacancy/item/:id",
    component: () => <EditVacancy title="" base="Ваканции" />,
  },
  {
    path: "/tender",
    component: () => <TenderList title="Вcе" base="Тендеры" />,
    exact: true,
  },
  {
    path: "/tender/add",
    component: () => <AddTender title="Добавить новый" base="Тендер" />,
  },
  {
    path: "/tender/item/:id",
    component: () => <EditTender title="Изменить" base="Тендер" />,
  },
//   {
//     path: "/applications",
//     component: () => <Applications title="Вcе" base="Заявки" />,
//     exact: true,
//   },
  {
    path: "/applications-tariff",
    component: () => <ApplicationsTechnique title="Вcе" base="Заявки" />,
    exact: true,
  },
  {
    path: "/applications-technique",
    component: () => <Applications title="Вcе" base="Заявки" />,
    exact: true,
  },
  {
    path: "/payments",
    component: () => <PaymentList title="Вcе" base="Способ оплаты" />,
    exact: true,
  },
  {
    path: "/payment/:id",
    component: () => <PaymentAddEdit title="Вcе" base="Способ оплаты" />,
    exact: true,
  },
  {
    path: "/services",
    component: () => <ServiceList title="Вcе" base="Услуги" />,
    exact: true,
  },
  {
    path: "/services/:id",
    component: () => <ServiceAddEdit title="Вcе" base="Услуги" />,
    exact: true,
  },
  {
    path: "/public-offer",
    component: () => <PublicOfferList title="Вcе" base="Публичная оферта" />,
    exact: true,
  },
  {
    path: "/public-offer/add",
    component: () => (
      <AddPublicOffer title="Добавить новое" base="Публичная оферта" />
    ),
    exact: true,
  },
  {
    path: "/public-offer/:id",
    component: () => (
      <EditPublicOffer title="Изменить" base="Публичную оферту" />
    ),
  },
  {
    path: "/company-settings",
    component: () => (
      <CompanySettingsList title="Вcе" base="Контакты компании" />
    ),
    exact: true,
  },
  {
    path: "/company-settings/add",
    component: () => (
      <AddCompanySettings title="Вcе" base="Контакты компании" />
    ),
    exact: true,
  },
  {
    path: "/register-user",
    component: () => <RegisterUser title="Добавить новое" base="Admin" />,
    exact: true,
  },
  {
    path: "/user/update",
    component: () => <UpdateUser title="Изменить данные" base="Admin" />,
    exact: true,
  },
  {
    path: "/etc-services",
    component: () => <EtServicesList title="Все" base="Etc Услуги" />,
    exact: true,
  },
  {
    path: "/etc-services/add",
    component: () => <AddEtServices title="Добавить новое" base="Etc Услуги" />,
    exact: true,
  },
  {
    path: "/etc-services/:id",
    component: () => <EditEtServices title="Изменить" base="Etc Услуги" />,
    exact: true,
  },
  {
    path: "/support/add",
    component: () => (
      <AddSupportCategories
        title="Добавить"
        base="Категории центра поддержки"
      />
    ),
    exact: true,
  },
  {
    path: "/support-categories",
    component: () => (
      <SupportCategoriesList title="Все" base="Категории центра поддержки" />
    ),
    exact: true,
  },
  {
    path: "/support-categories/:id",
    component: () => (
      <EditSupportCategories
        title="Изменить"
        base="Категории центра поддержки"
      />
    ),
    exact: true,
  },
  {
    path: "/company-routers",
    component: () => <CompanyRouterList title="Все" base="Роутеры" />,
    exact: true,
  },
  {
    path: "/company-routers/:id",
    component: () => <CompanyRouterEdit title="Изменить" base="Роутер" />,
    exact: true,
  },
  {
    path: "/company/routers/add",
    component: () => <CompanyRouterAdd title="Добавить" base="Роутер" />,
    exact: true,
  },
  {
    path: "/banners",
    component: () => <Banners title="Все" base="Категории центра поддержки" />,
    exact: true,
  },
];
