const ROUTES = {
    news_all: "/news/all",
    news_add: "/news/add",
    newsCats: "/news/cats",
    tariff_add: "/tariff/item/add",
    faq_add: "/faq/add",
    contact_add: "/contacts/add",
    vacancy_add: "/vacancy/add",
    tender_add: "/tender/add",
    public_offer_add: "/public-offer/add",
    company_settings_add: "/company-settings/add",
    support_categories_add: "/support/add",
    company_router_add: "/company/routers/add",
};
export default ROUTES
