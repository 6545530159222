import React from "react";
import {NavLink} from "react-router-dom";

const Title = (props) => {
  return(
    <div className="page-header row no-gutters py-4 d-flex align-items-center">
      <div className="col-12 col-sm-3 text-center text-sm-left mb-0">
        <span className="text-uppercase page-subtitle">{props.base}</span>
        <h3 className="page-title">{props.title}</h3>
      </div>
      <div className="col-12 col-sm-6 text-center text-sm-left mb-0 justify-content-center">
        {props.children}
      </div>
      {props.addNewButtonUrl && (
        <div className="col-12 col-sm-3 text-right mb-0">
          <NavLink to={props.addNewButtonUrl} className="btn-lg btn btn-primary">
            {props.editButton ? "Изменить" : "Добавить новое"}
          </NavLink>
        </div>
      )}
    </div>
  )
};

export default Title