import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import {LANG_UPPER} from "../../../constants/GLOBAL";
import {objectToArrayForSelect} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import BlockLoader from "../../ui/loader/BlockLoader";
import PaymentItem from "./PaymentItem";
import {api} from "../../../api/api";
import {toast} from "react-toastify";

const PaymentList = (props) => {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru
    });
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState(null);
    const [reRender, setRender] = useState(false)

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setLoading(true)
            api.get("payment-methods-info/list?lang=" + filter.lang).then(res => {
                setLoading(false)
                setList(res.payload)
            });
        }
        return () => mounted = false;
    }, [filter, reRender]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleDelete = (id) => {
        setLoading(true)
        api.delete("payment-methods-info/delete/" + id).then(res => {
            setRender(!reRender);
            toast.success("Удалено");
        })
    }

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={"/payment/add"}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>

            {isLoading ? <BlockLoader/> : (
                <>

                    <div className="row">
                        {list && list.map((item, key) => (
                            <PaymentItem
                                data={item}
                                key={key}
                                onDelete={handleDelete}
                            />
                        ))}
                    </div>

                    {list && list.length === 0 && (
                        <div className="font-weight-bold"><h4>Пусто</h4></div>
                    )}
                </>
            )}
        </div>
    )
}

export default PaymentList