import * as t from './mainTypes';

export const setLoading = (type) => (dispatch) => {
  dispatch({type: t.SET_LOADING,payload: type})
}

export const setToken = (type) => (dispatch) => {
  dispatch({type: t.SET_TOKEN,payload: type})
}

export const setLagn = (type) => (dispatch) => {
  dispatch({type: t.SET_LANG,payload: type})
}