import React, {useEffect, useState} from "react";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {api} from "../../../api/api";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import {toast} from "react-toastify";
import ROUTES from "../../../constants/ROUTES";
import Select from "../../ui/select/Select";
import Title from "../../title/Title";
import TenderItem from "./components/list/TenderItem";
import BlockLoader from "../../ui/loader/BlockLoader";

export default function TenderList(props) {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        companyName: BRANDS.tps
    });
    const [tender, setTender] = useState();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setLoading(true)
            api.get("tender/list" + objectToUrl(filter))
                .then(response => {
                    if (response && response.payload) {
                        setLoading(false)
                        setTender(response.payload)
                    }
                })
                .catch(error => {
                    setLoading(false)
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
        return () => mounted = false;
    }, [filter]);
    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setTender(null)
    };

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.tender_add}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="contact_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="contact_brand"
                                extraClassForSelect="mini"
                                label="Company"
                                values={objectToArrayForSelect(BRANDS)}
                                filterName="companyName"
                                activeValue={filter.companyName}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                {tender && tender.map((item, index) => {
                    return (
                        <TenderItem key={index} data={item}/>
                    )
                })}
            </div>
            {isLoading && (
                <BlockLoader/>
            )}
            <div>
                {tender && tender.length === 0 && (
                    <div className="font-weight-bold"><h4>Пусто</h4></div>
                )}
            </div>
        </div>
    )
}
