import {NavLink, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LANG_UPPER} from "../../../constants/GLOBAL";
import {toast} from "react-toastify";
import {api} from "../../../api/api";
import Title from "../../title/Title";
import {objectToArrayForSelect} from "../../../utils/helpers";
import Select from "../../ui/select/Select";


export default function AddVacancy(props) {
    const history = useHistory();
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru
    });
    const [data, setData] = useState(
        {
            computerSkills: "",
            education: "",
            email: "",
            employer: "",
            extraRequirements: "",
            jobName: "",
            lang: filter.lang.toLocaleUpperCase(),
            phoneNumber: "",
            position: "",
            requirements: ""
        }
    );

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            lang: filter.lang.toLocaleUpperCase()
        }))
    }, [filter]);
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.computerSkills) &&
            Boolean(data.education) &&
            Boolean(data.email) &&
            Boolean(data.employer) &&
            Boolean(data.extraRequirements) &&
            Boolean(data.jobName) &&
            Boolean(data.phoneNumber) &&
            Boolean(data.position) &&
            Boolean(data.requirements)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.post("vacancy/etc/create", data)
                .then(() => {
                    history.push("/vacancy");
                    toast.success("Успешно добавлено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="contact_language"
                                    extraClassForSelect="mini"
                                    label="Language"
                                    values={objectToArrayForSelect(LANG_UPPER)}
                                    filterName="lang"
                                    activeValue={filter.lang}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.jobName}
                                placeholder="Наименование ваканции"
                                onChange={(e) => handleChange("jobName", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.requirements}
                                placeholder="Требования"
                                onChange={(e) => handleChange("requirements", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.computerSkills}
                                placeholder="Знание компьютерных программ"
                                onChange={(e) => handleChange("computerSkills", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.education}
                                placeholder="Образование"
                                onChange={(e) => handleChange("education", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.position}
                                placeholder="Позиция"
                                onChange={(e) => handleChange("position", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.extraRequirements}
                                placeholder="Дополнительные требования"
                                onChange={(e) => handleChange("extraRequirements", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.email}
                                placeholder="Э-почта"
                                onChange={(e) => handleChange("email", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.employer}
                                placeholder="Менеджер"
                                onChange={(e) => handleChange("employer", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.phoneNumber}
                                placeholder="Телефонный номер"
                                onChange={(e) => handleChange("phoneNumber", e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <div className="card card-small mb-3">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex px-3">
                                            <NavLink to="/vacancy" className="btn btn-outline-accent">
                                                Отмена
                                            </NavLink>
                                            <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                                Публиковать
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}