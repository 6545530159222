import React, {useEffect, useState}  from 'react';
import ROUTES from "../../../constants/ROUTES";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import Title from "../../title/Title";
import {api} from "../../../api/api";
import ContactsItem from "./copmonents/ContactsItem";
import {Table, Tbody, Th, Thead, Tr} from "react-super-responsive-table";

export default function ContactsList(props) {

    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        companyName: BRANDS.tps
    });



    const [contacts, setContacts] = useState(null);

    useEffect(() => {
        api.get("company-contact/list" + objectToUrl(filter)).then(res => {
            if (res && res.payload) {
                setContacts(res.payload)
            }
        });

    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setContacts(null)
    };

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.contact_add}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="contact_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="contact_brand"
                                extraClassForSelect="mini"
                                label="Company"
                                values={objectToArrayForSelect(BRANDS)}
                                filterName="companyName"
                                activeValue={filter.companyName}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            <div className="bg-white border-rad">
                <Table className="table table-hover">
                    <Thead>
                        <Tr>
                            <Th scope="col">#</Th>
                            <Th scope="col">Э-почта</Th>
                            <Th scope="col">Телефон</Th>
                            <Th scope="col">Страна</Th>
                            <Th scope="col">Город</Th>
                            <Th scope="col">Область</Th>
                            <Th scope="col">Улица, дом</Th>
                            <Th scope="col">Действие</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                {contacts && contacts.map((item, index) => {
                    return (

                      <ContactsItem key={index} data={item} index={index}/>

                    )

                })}
                    </Tbody>
                </Table>
                {contacts && contacts.length === 0 && (
                    <div className="font-weight-bold"><h4>Пусто</h4></div>
                )}
            </div>
        </div>
    );
}


