import React, { useEffect, useState } from "react";
import Title from "../../title/Title";
import Select from "../../ui/select/Select";
import { objectToArrayForSelect, uploadPlugin } from "../../../utils/helpers";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LANG_UPPER, VISIBILITY_PC } from "../../../constants/GLOBAL";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { NavLink, useHistory, useParams } from "react-router-dom";
import ROUTES from "../../../constants/ROUTES";
import { toast } from "react-toastify";
import { GrClose } from "react-icons/all";
import { uploadFile } from "../../../constants/API";
import { api } from "../../../api/api";

export function EditEtServices(props) {
    const { id } = useParams();
    const history = useHistory();

    const [filter, setFilter] = useState({
        lang: "",
        visibility: "",
    });
    const [loaded, setLoaded] = useState(false);

    const [data, setData] = useState(null);

    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            api.get("services/get/etc_service/" + id).then((res) => {
                setFilter({
                    lang: res.payload.lang,
                    visibility: res.payload.visibility,
                });
                setData(res.payload);
                setLoaded(true)
            });
        }
        return () => (mounted = false);
    }, [id]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setData((d) => {
                return {
                    ...d,
                    lang: filter.lang,
                    visibility: filter.visibility,
                };
            });
        }
        return () => (mounted = false);
    }, [filter]);

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", file);
        setFormData(newFormData);
    }, [file]);

    useEffect(() => {
        if (formData) {
                uploadFile(formData).then((res) => {
                    setData((d) => {
                        return {
                            ...d,
                            bannerMedia: res.url,
                        };
                    });
                });
        }
    }, [formData]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value,
        });
    };

    const handleChange = (name, value) => {
        if (loaded) {setData({
            ...data,
            [name]: value,
        });}
    };
    const handleSubmit = () => {
        console.log(data.name);
        if (
            !(
                Boolean(data.name) &&
                Boolean(data.bannerMedia) &&
                Boolean(data.bannerTitle) &&
                Boolean(data.bannerDescription)
            )
        ) {
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            api.put("services/update/etc_service", data).then((response) => {
                toast.success("Успешно изменено", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                history.push("/etc-services");
                if (response && response.payload) {
                    setData(response.payload);
                }
            });
        }
    };


    useEffect(() => console.log(data), [data])

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <Select
                                id="language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0 d-flex">
                            <Select
                                id="visibility"
                                extraClassForSelect="mini"
                                label="Visibility"
                                values={objectToArrayForSelect(VISIBILITY_PC)}
                                filterName="visibility"
                                activeValue={filter.visibility}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            {data && (
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-body">
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    value={data.name}
                                    placeholder="Имя"
                                    onChange={(e) => handleChange("name", e.target.value)}
                                />
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    value={data.bannerTitle}
                                    placeholder="Заголовок Баннера"
                                    onChange={(e) => handleChange("bannerTitle", e.target.value)}
                                />
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="number"
                                    value={data.serviceOrder}
                                    placeholder="Порядок"
                                    onChange={(e) => handleChange("serviceOrder", e.target.value)}
                                />
                                <textarea
                                    className="form-control form-control-lg mb-3"
                                    value={data.bannerDescription}
                                    placeholder="Описание Баннера"
                                    onChange={(e) =>
                                        handleChange("bannerDescription", e.target.value)
                                    }
                                />
                                <Tabs>
                                    <TabList>
                                        <Tab>Описание</Tab>
                                        <Tab>Тарифы</Tab>
                                        <Tab>Спецификации</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin],
                                            }}
                                            editor={ClassicEditor}
                                            data={data.descriptionTab}
                                            onChange={(event, editor) =>
                                                handleChange("descriptionTab", editor.getData())
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin],
                                            }}
                                            editor={ClassicEditor}
                                            data={data.tariffTab}
                                            onChange={(event, editor) =>
                                                handleChange("tariffTab", editor.getData())
                                            }
                                        />
                                    </TabPanel>
                                    <TabPanel>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin],
                                            }}
                                            editor={ClassicEditor}
                                            data={data.specification}
                                            onChange={(event, editor) =>
                                                handleChange("specification", editor.getData())
                                            }
                                        />
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        <div className="card card-small mt-3 mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Загрузить фото</h6>
                            </div>
                            <div className="card-body">
                                <div className="mb-4">
                                    {data && data.bannerMedia && (
                                        <img src={data?.bannerMedia} alt="" />
                                    )}
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="form-control form-control-lg mb-3"
                                            type="file"
                                            id="tariff_logo__input"
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                setFile(e.target.files[0]);
                                            }}
                                        />
                                        <label
                                            htmlFor="tariff_logo__input"
                                            className={
                                                "tariff_logo__label " +
                                                (file ? "bg-success" : "bg-primary")
                                            }
                                        >
                                            {file ? file?.name : "Выберите изображение"}
                                        </label>
                                    </div>
                                    {file && (
                                        <button
                                            onClick={(e) => {
                                                setData({
                                                    ...data,
                                                    bannerMedia: "",
                                                });
                                                setFile(null);
                                            }}
                                            className="btn btn-danger p-2 file-remove-btn"
                                        >
                                            <GrClose />
                                        </button>
                                    )}
                                </div>
                                <p className="mb-1 font-weight-normal">
                                    Максималыный размер изображения 5mb. <br /> Тип{" "}
                                    <b>.png, .jpg</b> поддерживает.
                                </p>
                                {file && (
                                    <div>
                                        <p className="mb-2">
                                            Размер:{" "}
                                            <span className="text-primary">
                        {(file?.size / 1e6).toFixed(2)} MB{" "}
                      </span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card card-small mb-3">
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex px-3">
                                        <NavLink
                                            to={ROUTES.news_all}
                                            className="btn btn-outline-accent"
                                        >
                                            Отмена
                                        </NavLink>
                                        <button
                                            className="btn btn-success ml-auto"
                                            onClick={handleSubmit}
                                        >
                                            Публиковать
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
