

import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {NavLink,useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {api} from "../../../api/api";

export default function AddContact(props) {
    const history = useHistory();
    // const [validate, setValidate] = useState(false);
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.uz,
        companyName: BRANDS.tps
    });
    const [data, setData] = useState(
        {
            building: "",
            city: "",
            companyName: filter.companyName.toLocaleUpperCase(),
            country: "Узбекистан",
            email: "",
            lang: filter.lang.toLocaleUpperCase(),
            phoneNumber: "",
            region: "",
            street: ""
        }
    );

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };


    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            lang: filter.lang.toLocaleUpperCase(),
            companyName: filter.companyName.toLocaleUpperCase()
        }));

    }, [filter]);




    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };



    const handleSubmit = () =>{

        if(!(
            Boolean(data.email) &&
            Boolean(data.phoneNumber) &&
            Boolean(data.region) &&
            Boolean(data.street) &&
            Boolean(data.building) &&
            Boolean(data.city)
        )){
            // setValidate(true);
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        }else{
            api.post("company-contact/create", data).then(res=> {
                history.push("/contacts");
                toast.success("Успешно добавлено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    };
    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="contact_language"
                                    extraClassForSelect="mini"
                                    label="Language"
                                    values={objectToArrayForSelect(LANG_UPPER)}
                                    filterName="lang"
                                    activeValue={filter.lang}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="contact_brand"
                                    extraClassForSelect="mini"
                                    label="Company"
                                    values={objectToArrayForSelect(BRANDS)}
                                    filterName="companyName"
                                    activeValue={filter.companyName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.email}
                                placeholder="Э-почта"
                                onChange={(e) => handleChange("email", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.phoneNumber}
                                placeholder="Телефон номер"
                                onChange={(e) => handleChange("phoneNumber", e.target.value)}
                            />
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="pl-3 pt-3">
                            <h4>Адресс</h4>
                        </div>
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.city}
                                placeholder="Город"
                                onChange={(e) => handleChange("city", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.region}
                                placeholder="Область"
                                onChange={(e) => handleChange("region", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.street}
                                placeholder="Улица"
                                onChange={(e) => handleChange("street", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.building}
                                placeholder="Дом"
                                onChange={(e) => handleChange("building", e.target.value)}
                            />
                        </div>
                        <div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-3 col-md-12">

                    <div className="card card-small mb-3">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/contacts" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                        Публиковать
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
