

import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {objectToArrayForSelect} from "../../../utils/helpers";
import { LANG_UPPER} from "../../../constants/GLOBAL";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {api} from "../../../api/api";
import {GrClose} from "react-icons/all";
import {uploadFile} from "../../../constants/API";

export default function PaymentAddEdit(props) {
    const {id} = useParams();
    const history = useHistory();
    const isAdd = id === "add";
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(null);

    const [data, setData] = useState({
        name: "",
        info: "",
        imageLink: "",
        lang: ""
    });


    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", file);
        setFormData(newFormData)
    }, [file]);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            if(id !== "add"){
                api.get("payment-methods-info/" + id).then(res => {
                    if (res && res.payload) {
                        setData(res.payload);
                    }
                })
            }
        }
        return () => mounted = false;
    }, [id]);

    const handleSubmit = () =>{
        if(!(
            Boolean(data.name) &&
            Boolean(data.info) &&
            Boolean(file || data.imageLink)
        )){
            // setValidate(true);
            toast.error("Некоторые поля должны быть заполнены. Заголовок, Описание, изображение", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {

            if (isAdd) {
                uploadFile(formData).then(res => {
                    api.post("payment-methods-info/create", {
                        ...data,
                        imageLink: res.url
                    }).then(()=> {
                        history.push("/payments");
                        toast.success("Успешно добавлено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                })
            } else {
                file ? uploadFile(formData).then(res => {
                    api.put("payment-methods-info/update", {
                        ...data,
                        imageLink: res.url
                    }).then( () => {
                        history.push("/payments");
                        toast.success("Успешно добавлено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                }) : api.put("payment-methods-info/update", data).then( () => {
                    history.push("/payments");
                    toast.success("Успешно добавлено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }

        }
    };
    return (
        <div>
            <Title
                title={isAdd ? "Добавить новое" : "Изменить"}
                base={props.base}
            />
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.name}
                                placeholder="Заголовок"
                                onChange={(e) => handleChange("name", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                style={{minHeight: 125}}
                                value={data.info}
                                placeholder="Описание"
                                onChange={(e) => handleChange("info", e.target.value)}
                            />
                            {
                                !isAdd ? (
                                    <img src={file ? URL.createObjectURL(file) : data.imageLink} alt=""/>
                                )  : (
                                    <img src={file && URL.createObjectURL(file)} alt=""/>
                                )
                            }
                            <div className="d-flex">
                                <div>
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="file"
                                        id="tariff_logo__input"
                                        accept=".jpg , .png"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                    <label
                                        htmlFor="tariff_logo__input"
                                        className={"tariff_logo__label " + (file ? "bg-success" : "bg-primary")}
                                    >
                                        {file ? file?.name : "Выберите изображение"}
                                    </label>
                                </div>
                                {file && <button onClick={(e) => setFile( null)} className="btn btn-danger p-2 file-remove-btn"><GrClose/></button>}
                            </div>
                            <p className="mb-1 font-weight-normal">Максималыный размер изображения 5mb. <br/> Тип <b>.png, .jpg</b> поддерживает.</p>
                            {file && <div>
                                <p className="mb-2">
                                    Размер: <span className="text-primary">{(file?.size / 1e+6).toFixed(2)} MB </span>
                                </p>
                            </div>}
                        </div>
                    </div>
                </div>


                <div className="col-lg-3 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Язык</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(LANG_UPPER).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"payment_lang_" + item.value}
                                                name="lang"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.lang === item.value}
                                                onChange={(e) => handleChange("lang", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"payment_lang_" + item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/payments" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                        {isAdd ? "Публиковать" : "Изменить"}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
