import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {NavLink, useHistory, useParams} from 'react-router-dom';
import {api} from "../../../api/api";
import {toast} from "react-toastify";


export function EditSupportCategories(props) {
    const {id} = useParams();
    const history = useHistory();
    const [dataItem, setDataItem] = useState(null);
    const [data, setData] = useState({
        title: "",
        link: ""
    });
    useEffect(() => {
        api.get("support/" + id).then(res => {
            if (res && res.payload) {
                setDataItem(res.payload);
                setData(res.payload)
            }
        })
    }, [id]);

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.title) &&
            Boolean(data.link)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.put("support/update/" + id, data)
                .then(() => {
                    history.push("/support-categories");
                    toast.success("Успешно изменено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}/>
            {
                dataItem && (
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="card card-small mb-3">
                                <div className="card-body">
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="text"
                                        value={data.title}
                                        placeholder="Имя"
                                        onChange={(e) => handleChange("title", e.target.value)}
                                    />
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="text"
                                        value={data.link}
                                        placeholder="Ссылка"
                                        onChange={(e) => handleChange("link", e.target.value)}
                                    />

                                </div>
                                    <div className="card-body p-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-3">
                                                <NavLink to="/faq" className="btn btn-outline-accent">
                                                    Отмена
                                                </NavLink>
                                                <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                                    Изменить
                                                </button>
                                            </li>
                                        </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

