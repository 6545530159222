import React from "react";
import { AiOutlineDelete } from "react-icons/all";

export default function ApplicationsTechniqueItem(props) {
  return (
    <tr>
      <td>{props.index}</td>
      <td>
        {props.data?.createdDateForSort.substring(0, 10) +
          " " +
          props.data?.createdDateForSort.substring(11, 19)}
      </td>
      <td>{props.data?.firstName}</td>
      <td>{props.data?.lastName}</td>
      <td>{props.data?.address}</td>
      <td>{props.data?.phoneNumber}</td>
      <td>{props.data?.email}</td>
      <td>{props.data?.companyNameEnum}</td>
      <td>{props.data?.tariffName}</td>
      {/* <td>
        {console.log(props.data)}
        {props.data?.applicationTypeEnum !== null
          ? props.data?.applicationTypeEnum === "OFFER"
            ? "Предложение"
            : "Заявка на тариф"
          : props.data.complaintTypeEnum === "MODEM_SETTINGS"
          ? "Настройка модема"
          : props.data?.complaintTypeEnum === "CHANGE_TARIFF"
          ? "Смена тарифа"
          : props.data?.complaintTypeEnum === "PROBLEMS_WITH_SPEED"
          ? "Проблемы со скоростью"
          : props.data?.complaintTypeEnum === "PAYMENT_FOR_THE_TARIFF"
          ? "Оплата за тариф"
          : props.data?.complaintOtherType}
      </td> */}
      <td>{props.data?.message}</td>
      <td>
        <button
          onClick={() => props.onDelete(props.data.id)}
          className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
        >
          <AiOutlineDelete className="mr-1" />
          Delete
        </button>
      </td>
    </tr>
  );
}
