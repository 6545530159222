const AgreeModal = (props) => {

    return (
        <div className="modal fade show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>
                        <button onClick={props.onCancel} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    {props.body && (
                        <div className="modal-body text-center">
                            <p className="text-dark mb-0">{props.body}</p>
                        </div>
                    )}

                    <div className="modal-footer justify-content-center">
                        <button onClick={props.onCancel} type="button" className="btn btn-danger" data-dismiss="modal">Отмена</button>
                        <button onClick={props.onSubmit} type="button" className="btn btn-primary">Удалить</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgreeModal