import React, {useState} from 'react';
import {objectToArrayForSelect} from "../../../utils/helpers";
import {BRANDS, FAQ_CATEGORY, LANG, VISIBILITY} from "../../../constants/GLOBAL";
import Title from "../../title/Title";
import {NavLink, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {api} from "../../../api/api";

export default function AddFaqs(props) {
    const history = useHistory()

    const [data, setData] = useState({
        answer: "",
        companyNameEnum: "",
        faqCategoryEnum: "",
        faqVisibilityEnum: "",
        lang: "",
        question: "",
    });

    const [validate, setValidate] = useState(false)

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () =>{
        if(!(
            Boolean(data.answer) &&
            Boolean(data.question) &&
            Boolean(data.companyNameEnum) &&
            Boolean(data.faqCategoryEnum)
        )){
            setValidate(true)
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        }else{
            setValidate(false)
            api.post("faq", data).then(()=> {
                history.push("/faq")
                toast.success("Успешно добавлено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    return (
        <div>
            <Title title={props.title} base={props.base}/>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.question}
                                placeholder="Вопрос"
                                onChange={(e) => handleChange("question", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.answer}
                                placeholder="Ответ"
                                onChange={(e) => handleChange("answer", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-12">
                    {validate && (
                        <div className="bg-danger rounded text-white text-center p-3 mb-4">
                            Пожалуйста заполните все поля
                        </div>
                    )}

                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Язык</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(LANG).map((item, key)=>(
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"faq_lang_"+item.value}
                                                name="lang"
                                                className="custom-control-input"
                                                value={(item.value).toUpperCase()}
                                                checked={data.lang === (item.value).toUpperCase()}
                                                onChange={(e)=>handleChange("lang", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"faq_lang_"+item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Тип компании</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(BRANDS).map((item, key)=>(
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"faq_brand_"+item.value}
                                                name="companyNameEnum"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.companyNameEnum === item.value}
                                                onChange={(e) => handleChange("companyNameEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"faq_brand_"+item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Тип Faqs</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(FAQ_CATEGORY).map((item, key)=>(
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"faq_cat_"+item.value}
                                                name="faqCategoryEnum"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.faqCategoryEnum === item.value}
                                                onChange={(e) => handleChange("faqCategoryEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"faq_cat_"+item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Тип Видимость</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(VISIBILITY).map((item, key)=>(
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"faq_vis_"+item.value}
                                                name="faqVisibilityEnum"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.faqVisibilityEnum === item.value}
                                                onChange={(e) => handleChange("faqVisibilityEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"faq_vis_"+item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/faq" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button onClick={handleSubmit} className="btn btn-success ml-auto" >
                                        Публиковать
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

