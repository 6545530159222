import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import {LANG} from "../../../constants/GLOBAL";
import {objectToArrayForSelect} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import BlockLoader from "../../ui/loader/BlockLoader";
import {getCats, addCat, deleteCat, editCat} from "../news/actions/newsActions";
import {toast} from "react-toastify";
import CategoryItem from "./CategoryItem";

const ModeAdd = "ModeAdd"
const ModeEdit = "ModeEdit"
const ModeDelete = "ModeDelete"

const CategoryList = (props) => {
    const [filter, setFilter] = useState({
        lang: LANG.uz
    });
    const [isLoading, setLoading] = useState(true);
    const [cats, setCats] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [isEditAdd, setEditAdd] = useState(ModeAdd)
    const [data, setData] = useState({
        name: ""
    })
    const [reRender, setRender] = useState(false)

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setCats(null)
            getCats(filter.lang).then(data => {
                setLoading(false)
                setCats(data)
            })
        }
        return () => mounted = false;
    }, [filter, reRender]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(currentItem){
            editCat(currentItem.id, {
                ...data,
                lang: filter.lang,
                categoryId: currentItem.id
            }).then(() => {
                setRender(!reRender);
                setData({
                    ...data,
                    name: ""
                })
                setEditAdd(ModeAdd)
                setCurrentItem(null)
                toast.success("Успешно изменено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }else{
            addCat({
                ...data,
                lang: filter.lang,
            }).then(() => {
                setRender(!reRender);
                setData({
                    ...data,
                    name: ""
                })
                toast.success("Успешно добавлено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }


    const onDeleteItem = (item, realDelete) => {
        if(realDelete){
            deleteCat(item.id).then(() => {
                setRender(!reRender);
                setEditAdd(ModeAdd)
                setCurrentItem(null)
                toast.success("Успешно удалено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }else{
            setEditAdd(ModeDelete)
            setCurrentItem(item)
        }
    }

    const onEditItem = (item) => {
        setCurrentItem(item)
        setEditAdd(ModeEdit)
        setData({
            ...data,
            name: item.name
        })
    }

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>

            <div className="row">

                {isEditAdd &&
                <div className="col-md-4 col-12 order-md-last">
                    <div className="edit-modal">
                        <div className="edit-modal-title">
                            {isEditAdd === ModeAdd
                                ? "Добавить новое"
                                : (isEditAdd === ModeDelete
                                    ? `${currentItem && currentItem.name}: Вы уверены, что хотите удалить?`
                                    : `Изменить: ${currentItem && currentItem.name}`
                                )
                            }
                        </div>
                        <div className="edit-modal-body">
                            {isEditAdd === ModeDelete ? (
                                <div>
                                    <div className="d-flex justify-content-between">
                                        {isEditAdd !== ModeAdd ? <button onClick={() => setEditAdd(ModeAdd)} className="btn btn-primary">
                                            Добавить новое
                                        </button> : <div/>}
                                        <button onClick={() => onDeleteItem(currentItem, true)} className="btn btn-danger ">
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <input
                                        className="form-control form-control mb-3"
                                        type="text"
                                        value={data.name}
                                        placeholder="Название категории"
                                        onChange={(e)=>handleChange("name", e.target.value)}
                                    />
                                    <div className="d-flex justify-content-between">
                                        {isEditAdd !== ModeAdd ? <button onClick={() => setEditAdd(ModeAdd)} className="btn btn-primary">
                                            Добавить новое
                                        </button> : <div/>}
                                        <button className="btn btn-success">
                                            {isEditAdd === ModeAdd ? "Добавить" : "Изменить"}
                                        </button>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
                }
                <div className="col-md-8 col-12 order-md-first">
                    <div className="list-group">
                        {cats && cats.map((item, i)=> (
                            <CategoryItem
                                key={i}
                                id={item.categoryId}
                                title={item.name}
                                onDelete={onDeleteItem}
                                onEdit={onEditItem}
                            />
                        ))}
                    </div>

                    {cats && cats.length === 0 && (
                        <div className="font-weight-bold"><h4>Пусто</h4></div>
                    )}
                    {isLoading && (
                        <BlockLoader/>
                    )}
                </div>
            </div>

        </div>
    )
}

export default CategoryList