import {uploadFile} from "../constants/API";

export function objectToUrl(obj){
  let str = "?";
  for (let key in obj) {
    if (str !== "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }
  return str
}

export function findInObject(obj, name, value, returnName){
  for (let key in obj) {
    if (+obj[key][name] === +value) return obj[key][returnName]
  }
}

export function objectToArrayForSelect(obj){
  let arr= []
  for(let key in obj){
    arr.push({
      value: obj[key],
      label: key
    })
  }
  return arr
}

export function alphabetSort(array, name){
  return array.sort(function(a,b){
    return a[name].localeCompare(b[name]);
  })
}

export function trimString(string, limit){
  return string.length > limit ? string.substr(0, limit)+"..." : string
}


function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        //--------------
        const body = new FormData();
        loader.file.then((file) => {
          body.append("file", file);
          // let headers = new Headers();
          // headers.append("Origin", "http://localhost:3000");
          uploadFile(body)
              .then((res) => {
                console.log(res)
                resolve({
                  default: res.url
                });
              })
              .catch((err) => {
                reject(err);
              });
        });
      });
    }
  };
}
export function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}