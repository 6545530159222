import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import ROUTES from "../../../constants/ROUTES";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import {BRANDS} from "../../../constants/GLOBAL";
import {api} from "../../../api/api";
import {toast} from "react-toastify";
import BlockLoader from "../../ui/loader/BlockLoader";
import CompanySettingsItem from "./companySettingsItem";

export function CompanySettingsList(props) {
    const [filter, setFilter] = useState({
        brandName: BRANDS.tps
    });

    const [companySettings, setCompanySettings] = useState();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            api.get("/settings/get" + objectToUrl(filter))
                .then(response => {
                    if (response && response.payload) {
                        setLoading(false);
                        setCompanySettings(response.payload)
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
        return () => mounted = false;
    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setCompanySettings(null)
    };

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.company_settings_add}
                editButton={true}
            >
                <form>
                    <div className="form_row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="contact_brand"
                                    extraClassForSelect="mini"
                                    values={objectToArrayForSelect(BRANDS)}
                                    label="Company"
                                    filterName="brandName"
                                    activeValue={filter.brandName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <CompanySettingsItem data={companySettings}/>
            </div>
            {isLoading && (
                <BlockLoader/>
            )}
            <div>
                {companySettings && companySettings.length === 0 && (
                    <div className="font-weight-bold"><h4>Пусто</h4></div>
                )}
            </div>
        </div>
    );
}

