import React, {useState} from 'react';
import Title from "../../title/Title";
import {LANG_UPPER} from "../../../constants/GLOBAL";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {NavLink, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {api} from "../../../api/api";

function AddPublicOffer(props) {
    const history = useHistory();
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
    });
    const [offers, setOffers] = useState({offer: ""});
    const [title, setTitle] = useState({
        title: "",
        lang: filter.lang.toLocaleUpperCase()
    });

    const handleFilterChange = (name, value) => {
            setFilter({lang: value});
            setTitle({
                ...title,
                lang: value.toLocaleUpperCase()})
    };

    const handleSubmit = () => {
        const offerList = [];
        offers.offer.split("\n").forEach((offer) => {
            offerList.push({content: offer})
        });

        if (!(
            Boolean(title.title) &&
            Boolean(offers.offer)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.post("public-offer/create", {title: title, offerList: offerList})
                .then(() => {
                    history.push("/public-offer");
                    toast.success("Успешно добавлено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="offer_language"
                                    extraClassForSelect="mini"
                                    label="Language"
                                    values={objectToArrayForSelect(LANG_UPPER)}
                                    filterName="lang"
                                    activeValue={filter.lang}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={title.title}
                                onChange={(e) =>
                                    setTitle({
                                        ...title,
                                        title: e.target.value
                                    })}
                                placeholder="Заголовок"
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={offers.offer}
                                onChange={(e) =>
                                    setOffers({offer: e.target.value})}
                                placeholder="Офферты"
                            />
                        </div>
                        <div className="mb-3">
                            <NavLink to="/public-offer" className="btn btn-outline-accent mx-3">
                                Отмена
                            </NavLink>
                            <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                Публиковать
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddPublicOffer;