import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {BRANDS, FAQ_CATEGORY, LANG_UPPER, VISIBILITY} from "../../../constants/GLOBAL";
import {NavLink, useHistory, useParams} from 'react-router-dom';
import {api} from "../../../api/api";
import {toast} from "react-toastify";


export function EditFaq(props) {
    const {id} = useParams();
    const history = useHistory();
    const [faqItem, setFaqItem] = useState(null);
    const [data, setData] = useState({
        answer: "",
        companyNameEnum: "",
        faqCategoryEnum: "",
        faqVisibilityEnum: "",
        question: "",
        lang: "UZ",
    });
    useEffect(() => {
        api.get("faq/" + id).then(res => {
            if (res && res.payload) {
                setFaqItem(res.payload);
                setData(res.payload)
            }
        })
    }, [id]);

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.answer) &&
            Boolean(data.companyNameEnum) &&
            Boolean(data.faqCategoryEnum) &&
            Boolean(data.faqVisibilityEnum) &&
            Boolean(data.question)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.put("faq/update/" + id, data)
                .then(() => {
                    history.push("/faq");
                    toast.success("Успешно изменено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
                .catch(error => {
                    toast.error(error.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}/>
            {
                faqItem && (
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <div className="card card-small mb-3">
                                <div className="card-body">
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="text"
                                        value={data.question}
                                        placeholder="Вопрос"
                                        onChange={(e) => handleChange("question", e.target.value)}
                                    />
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="text"
                                        value={data.answer}
                                        placeholder="Ответ"
                                        onChange={(e) => handleChange("answer", e.target.value)}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <div className="card card-small mb-3">
                                <div className="card-header border-bottom">
                                    <h6 className="m-0">Язык</h6>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item px-3 pb-2">
                                            {objectToArrayForSelect(LANG_UPPER).map((item, key) => (
                                                <div key={key} className="custom-control custom-radio mb-1">
                                                    <input
                                                        type="radio"
                                                        id={"faqs_lang_" + item.value}
                                                        name="lang"
                                                        className="custom-control-input"
                                                        value={item.value}
                                                        checked={data.lang === item.value}
                                                        onChange={(e) => handleChange("lang", e.target.value)}
                                                    />
                                                    <label
                                                        className="custom-control-label text-capitalize"
                                                        htmlFor={"faqs_lang_" + item.value}
                                                    >{item.label}</label>
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card card-small mb-3">
                                <div className="card-header border-bottom">
                                    <h6 className="m-0">Название бренда</h6>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item px-3 pb-2">
                                            {objectToArrayForSelect(BRANDS).map((item, key) => (
                                                <div key={key} className="custom-control custom-radio mb-1">
                                                    <input
                                                        type="radio"
                                                        id={"faqs_brand_" + item.value}
                                                        name="companyNameEnum"
                                                        className="custom-control-input"
                                                        value={item.value}
                                                        checked={data.companyNameEnum === item.value}
                                                        onChange={(e) => handleChange("companyNameEnum", e.target.value)}
                                                    />
                                                    <label
                                                        className="custom-control-label text-capitalize"
                                                        htmlFor={"faqs_brand_" + item.value}
                                                    >{item.label}</label>
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card card-small mb-3">
                                <div className="card-header border-bottom">
                                    <h6 className="m-0">Видимость</h6>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item px-3 pb-2">
                                            {objectToArrayForSelect(VISIBILITY).map((item, key) => (
                                                <div key={key} className="custom-control custom-radio mb-1">
                                                    <input
                                                        type="radio"
                                                        id={"faq_visibility_" + item.value}
                                                        name="visibility"
                                                        className="custom-control-input"
                                                        value={item.value}
                                                        checked={data.faqVisibilityEnum === item.value}
                                                        onChange={(e) => handleChange("faqVisibilityEnum", e.target.value)}
                                                    />
                                                    <label
                                                        className="custom-control-label text-capitalize"
                                                        htmlFor={"faq_visibility_" + item.value}
                                                    >{item.label}</label>
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card card-small mb-3">
                                <div className="card-header border-bottom">
                                    <h6 className="m-0">Категория</h6>
                                </div>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item px-3 pb-2">
                                            {objectToArrayForSelect(FAQ_CATEGORY).map((item, key) => (
                                                <div key={key} className="custom-control custom-radio mb-1">
                                                    <input
                                                        type="radio"
                                                        id={"faq_category_" + item.value}
                                                        name="category"
                                                        className="custom-control-input"
                                                        value={item.value}
                                                        checked={data.faqCategoryEnum === item.value}
                                                        onChange={(e) => handleChange("faqCategoryEnum", e.target.value)}
                                                    />
                                                    <label
                                                        className="custom-control-label text-capitalize"
                                                        htmlFor={"faq_category_" + item.value}
                                                    >{item.label}</label>
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card card-small mb-3">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex px-3">
                                            <NavLink to="/faq" className="btn btn-outline-accent">
                                                Отмена
                                            </NavLink>
                                            <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                                Изменить
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}

