import {NavLink, useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {toast} from "react-toastify";
import Title from "../../title/Title";
import Select from "../../ui/select/Select";
import {api} from "../../../api/api";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {GrClose} from "react-icons/all";
import {uploadFile} from "../../../constants/API";

export default function CompanyRouterAdd(props) {
    const history = useHistory();
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        companyName: BRANDS.etc
    });
    const [data, setData] = useState(
        {
            companyName: filter.companyName.toLocaleUpperCase(),
            description: "",
            imageLink: "",
            lang: filter.lang.toLocaleUpperCase(),
            model: "",
            price: "",
            title: ""
        }
    );
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState(null);


    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            lang: filter.lang.toLocaleUpperCase()
        }))
    }, [filter]);
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", file);
        setFormData(newFormData)
    }, [file]);

    const handleSubmit = () => {
        if (!(
            Boolean(data.description) &&
            Boolean(data.model) &&
            Boolean(data.price) &&
            Boolean(data.title)
        )) {
            toast.error("Заполните все поля", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            uploadFile(formData).then(res => {
                api.post("company-router/create", {
                    ...data,
                    imageLink: res.url
                })
                    .then(() => {
                        history.push("/company-routers");
                        toast.success("Успешно добавлено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                    .catch(error => {
                        toast.error(error.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
            })
        }
    };

    return (
        <div>
            <Title title={props.title} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0 d-flex">
                            <div className="form-group mr-3 mb-0">
                                <Select
                                    id="contact_language"
                                    extraClassForSelect="mini"
                                    label="Language"
                                    values={objectToArrayForSelect(LANG_UPPER)}
                                    filterName="lang"
                                    activeValue={filter.lang}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.title}
                                placeholder="Титуль"
                                onChange={(e) => handleChange("title", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.description}
                                placeholder="Описание"
                                onChange={(e) => handleChange("description", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.model}
                                placeholder="Модель"
                                onChange={(e) => handleChange("model", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.price}
                                placeholder="Цена"
                                onChange={(e) => handleChange("price", e.target.value)}
                            />

                            <img src={file ? URL.createObjectURL(file) : data.imageLink} alt=""/>

                            <div className="d-flex">
                                <div>
                                    <input
                                        className="form-control form-control-lg mb-3"
                                        type="file"
                                        id="tariff_logo__input"
                                        accept=".jpg , .png"
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                    <label
                                        htmlFor="tariff_logo__input"
                                        className={"tariff_logo__label " + (file ? "bg-success" : "bg-primary")}
                                    >
                                        {file ? file?.name : "Выберите изображение"}
                                    </label>
                                </div>
                                {file &&
                                <button onClick={() => setFile(null)} className="btn btn-danger p-2 file-remove-btn">
                                    <GrClose/></button>}
                            </div>
                            <p className="mb-1 font-weight-normal">Максималыный размер изображения 5mb. <br/> Тип <b>.png,
                                .jpg</b> поддерживает.</p>
                            {file && <div>
                                <p className="mb-2">
                                    Размер: <span className="text-primary">{(file?.size / 1e+6).toFixed(2)} MB </span>
                                </p>
                            </div>}
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                                <div className="p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex px-3">
                                            <NavLink to="/company-routers" className="btn btn-outline-accent">
                                                Отмена
                                            </NavLink>
                                            <button onClick={handleSubmit} className="btn btn-success ml-auto float-right">
                                                Публиковать
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
