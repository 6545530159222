import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import {api} from "../../../api/api";
import {LANG} from "../../../constants/GLOBAL";
import {objectToArrayForSelect, uploadPlugin} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import {NavLink} from "react-router-dom";
import ROUTES from "../../../constants/ROUTES";
import {useParams, useHistory} from 'react-router-dom'
import {toast} from "react-toastify";
import {getCats, getItem} from "./actions/newsActions";
import {GrClose} from "react-icons/all";
import {uploadFile} from "../../../constants/API";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditNews = (props) => {
    const {id} = useParams();
    const history = useHistory();

    const [filter, setFilter] = useState({
        lang: ""
    });

    const [data, setData] = useState(null);
    const [cats, setCats] = useState(null);


    const [file, setFile] = useState(null);
    const [liveFile, setLiveFile] = useState(null);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            getItem(id).then(res => {
                setFilter({
                    lang: res.lang
                });
                setData(res)
            })
        }
        return () => mounted = false;
    }, [id]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (filter.lang) {
                getCats(filter.lang).then(res => {
                    setCats(res)
                })
            }
        }
        return () => mounted = false;
    }, [filter]);

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", file);
        setFormData(newFormData)
    }, [file]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setData(null);
        getItem(id, filter.lang).then(res => {
            setData(res)
        })
    };

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () => {
        if (!(
            Boolean(data.title) &&
            Boolean(data.shortDescription)
        )) {
            toast.error("Заголовок и Краткое описание должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            if (file) {
                uploadFile(formData).then(res => {
                    let newData = {
                        ...data,
                        imageStorageList: [
                            res.url
                        ],
                        lang: filter.lang
                    };
                    api.put("news/update", newData).then(response => {
                        toast.success("Успешно изменено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        history.push("/news/all");
                        if (response && response.payload) {
                            setData(response.payload)
                        }
                    })
                })
            } else {
                let newData = {
                    ...data,
                    lang: filter.lang
                };
                api.put("news/update", newData).then(response => {
                    toast.success("Успешно изменено", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    history.push("/news/all");
                    if (response && response.payload) {
                        setData(response.payload)
                    }
                })
            }
        }
    };


    return (
        <div>
            <Title title={data ? data.title : "Загружается ..."} base={props.base}>
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                                disabled={true}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            {data && (
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-body">
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    value={data.title}
                                    placeholder="Заголовок"
                                    onChange={(e) => handleChange("title", e.target.value)}
                                />
                                <textarea
                                    className="form-control form-control-lg mb-3"
                                    value={data.shortDescription}
                                    placeholder="Краткое описание"
                                    onChange={(e) => handleChange("shortDescription", e.target.value)}
                                />
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin]
                                    }}
                                    editor={ ClassicEditor }
                                    data={data.content}
                                    onChange={ ( event, editor ) => handleChange("content", editor.getData())}
                                />
                            </div>
                        </div>

                        <div className="card card-small mt-3 mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Загрузить фото</h6>
                            </div>
                            <div className="card-body">

                                {data.imageStorageList && data.imageStorageList.length && (

                                    <div className="mb-4">
                                        <img src={data.imageStorageList[0]} alt=""/>
                                    </div>
                                )}
                                {liveFile && (
                                    <div className="mb-4">
                                        <img src={liveFile} alt=""/>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="form-control form-control-lg mb-3"
                                            type="file"
                                            id="tariff_logo__input"
                                            accept=".jpg , .png"
                                            onChange={(e) => {
                                                setFile(e.target.files[0]);
                                                setLiveFile(URL.createObjectURL(e.target.files[0]))
                                            }}
                                        />
                                        <label
                                            htmlFor="tariff_logo__input"
                                            className={"tariff_logo__label " + (file ? "bg-success" : "bg-primary")}
                                        >
                                            {file ? file?.name : "Выберите изображение"}
                                        </label>
                                    </div>
                                    {file && <button onClick={(e) => setFile(null)}
                                                     className="btn btn-danger p-2 file-remove-btn"><GrClose/></button>}
                                </div>
                                <p className="mb-1 font-weight-normal">Максималыный размер изображения
                                    5mb. <br/> Тип <b>.png, .jpg</b> поддерживает.</p>
                                {file && <div>
                                    <p className="mb-2">
                                        Размер: <span
                                        className="text-primary">{(file?.size / 1e+6).toFixed(2)} MB </span>
                                    </p>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Информация</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item p-3">
                    <span className="d-flex mb-2">
                      <strong className="mr-1">Тип бренда:</strong>
                        {data && data.visibility}
                    </span>
                                        <span className="d-flex mb-2">
                      <strong className="mr-1">Название бренда:</strong>
                                            {data && data.brandName}
                    </span>
                                        <span className="d-flex mb-2">
                      <strong className="mr-1">Дата добавления:</strong>
                                            {data && Number(data.createdDate) && new Date(Number(data.createdDate)).toLocaleDateString()}
                    </span>
                                    </li>
                                    <li className="list-group-item px-3 pb-2">
                                        <div className="custom-control custom-checkbox mb-1">
                                            <input
                                                type="checkbox"
                                                id="top"
                                                name="top"
                                                className="custom-control-input"
                                                onChange={(e) => handleChange("top", e.target.checked)}
                                                value={data.top}
                                                checked={data.top}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor="top"
                                            >Главные новости</label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Название бренда</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        {objectToArrayForSelect({
                                            banner: "BANNER",
                                            simple: "SIMPLE",
                                        }).map((item, key) => (
                                            <div key={key} className="custom-control custom-radio mb-1">
                                                <input
                                                    type="radio"
                                                    id={"news_type_" + item.value}
                                                    name="type"
                                                    className="custom-control-input"
                                                    value={item.value}
                                                    checked={data.type === item.value}
                                                    onChange={(e) => handleChange("type", e.target.value)}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={"news_type_" + item.value}
                                                >{item.label}</label>
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Категория</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        <select
                                            className="form-control"
                                            value={data.categoryId ? data.categoryId : 0}
                                            onChange={(e) => handleChange("categoryId", e.target.value)}
                                        >
                                            <option value="0">Выбирать</option>
                                            {cats && cats.map((item, key) => (
                                                <option
                                                    key={key}
                                                    value={item.categoryId}
                                                >{item.name}</option>
                                            ))}
                                        </select>
                                        <NavLink to={ROUTES.newsCats}
                                                 className="btn-link btn font-weight-bold float-right">
                                            Категории
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-small mb-3">
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex px-3">
                                        <NavLink to={ROUTES.news_all} className="btn btn-outline-accent">
                                            Отмена
                                        </NavLink>
                                        <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                            Изменить
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default EditNews
