import React, { useEffect, useState } from "react";
import { api } from "../../../api/api";
import { toast } from "react-toastify";
import Title from "../../title/Title";
import ApplicationsTechniqueItem from "./ApplicationsTechniqueItem";
import BlockLoader from "../../ui/loader/BlockLoader";
import { AiOutlineDelete, AiOutlineExport } from "react-icons/all";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function ApplicationsTechnique(props) {
  const [apps, setApps] = useState();
  const [reRender, setRender] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      api.get("application/list").then((response) => {
        if (response && response.payload) {
          setLoading(false);
          setApps(response.payload.content);
        }
      });
    }
    return () => (mounted = false);
  }, [reRender]);

  const handleDelete = (id) => {
    setLoading(true);
    api.delete("application/delete/" + id).then((response) => {
      if (response.status) {
        toast.success("Успешно удалено");
        setRender(!reRender);
      }
    });
  };

  const handleDeleteAll = () => {
    setLoading(true);
    api
      .delete("application/delete/")
      .then((response) => {
        if (response.status) {
          toast.success("Успешно удалено");
          setRender(!reRender);
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <Title title={props.title} base={props.base} />
        <div>
          {/* <button
            onClick={() => null}
            className="btn btn-sm btn-primary pl-3 pr-3  py-2 mr-1 text-uppercase"
          > */}
          {/* <AiOutlineExport className="mr-1" />
            Экспорт в Excel */}
          <ReactHTMLTableToExcel
            className="btn btn-sm btn-primary pl-3 pr-3  py-2 mr-1 text-uppercase"
            table="excelTable"
            filename="Заявки на тарифы"
            sheet="Sheet"
            buttonText="Экспорт в Excel"
          />
          {/* </button> */}
          <button
            onClick={() => handleDeleteAll()}
            className="btn btn-sm btn-danger pl-3 pr-3  py-2 text-uppercase"
          >
            <AiOutlineDelete className="mr-1" />
            Удалить всё
          </button>
        </div>
      </div>
      <div className="bg-white border-rad">
        {loading ? (
          <BlockLoader />
        ) : (
          <table className="table table-hover" id="excelTable">
            <thead>
              <tr>
                <th>№</th>
                <th>Дата</th>
                <th>Имя</th>
                <th>Фамилия</th>
                <th>Адрес</th>
                <th>Телефонный номер</th>
                <th>Электронная почта</th>
                <th>ET,EVO,TPS</th>
                <th>Название тарифа</th>
                {/* <th>Тип приложения</th> */}
                <th width={200}>Сообщение</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {apps &&
                [...apps]
                  .slice(0)
                  .filter((y) => y.applicationTypeEnum === "APPLICATION_FOR_TARIFF")
                  .sort(function (a, b) {
                    return (
                      new Date(b.createdDateForSort).getTime() -
                      new Date(a.createdDateForSort).getTime()
                    );
                  })
                  .map((item, key) => (
                    <ApplicationsTechniqueItem
                      key={key + "_application"}
                      index={key + 1}
                      data={item}
                      onDelete={handleDelete}
                    />
                  ))}
            </tbody>
          </table>
        )}
      </div>
      <div>
        {apps && apps.length === 0 && (
          <div className="font-weight-bold">
            <h4>Пусто</h4>
          </div>
        )}
      </div>
    </div>
  );
}
