import React from "react";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {trimString} from "../../../utils/helpers";
import {FiExternalLink} from "react-icons/all"
import {BRANDS} from "../../../constants/GLOBAL";
const ServiceItem = (props) => {

    console.log(props.data)

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card card-small card-post h-100">
                {props.data && props.data.imageLink && (
                    <div className="card-post__image img-overlay">
                        <img src={props.data.imageLink} className="max-height" alt="Services" />
                    </div>
                )}
                <div className="card-body">

                    {props.data && props.data.iconLink ? (
                        <>
                            <div className="row">
                                <div className="col-md-9">
                                    <h5 >
                                        <a className="card-title d-flex align-items-center" href={props.data.brandName !== BRANDS.etc ? "https://new.etc.uz/" +  props?.data?.brandName?.toLowerCase()  +"/service/"+ props.data.serviceId : props.data.description}>{props.data && props.data.title}{" "}<FiExternalLink  className="ml-1"/></a>
                                    </h5>
                                    <p className="card-text">
                                        {trimString(props.data && props.data.description, 130)}
                                    </p>
                                </div>
                                <div className="col-md-3">
                                    <img src={props.data.iconLink} className="max-height" alt="Icon" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <h5 className="card-title">{props.data && props.data.title}</h5>
                            <p className="card-text">
                                {trimString(props.data && props.data.description, 130)}
                            </p>
                        </>
                    )}
                </div>
                <div className="card-footer text-muted border-top py-3">
                    <div className="list-group-actions d-flex justify-content-between">
                        <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/services/"+props.data.serviceId}>
                            <FiEdit2 className="mr-1"/>
                            Изменить
                        </NavLink>
                        <button
                            className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
                            onClick={() => props.onDelete(props.data.serviceId)}
                        >
                            <AiOutlineDelete className="mr-1"/>
                            Удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem