import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {objectToArrayForSelect, uploadPlugin} from "../../../utils/helpers";
import {BRANDS, LANG_UPPER, VISIBILITY_PC} from "../../../constants/GLOBAL";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {GrClose} from "react-icons/all";
import {uploadFile} from "../../../constants/API";
import {addService, editService, getService} from "./ServiceActions";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import BlockLoader from "../../ui/loader/BlockLoader";

const tabs = {
    about: "aboutTab",
    price: "priceTab",
    activation: "activationTab"
}

export default function ServiceAddEdit(props) {
    const {id} = useParams();
    const history = useHistory();
    const isAdd = id === "add";
    const [file, setFile] = useState(null);
    const [icon, setIcon] = useState(null);
    const [formData, setFormData] = useState(null);
    const [formData2, setFormData2] = useState(null);
    const [tab, setTab] = useState(tabs.about);

    const [data, setData] = useState({
        title: "",
        description: "",
        imageLink: "",
        iconLink: "",
        visibility: VISIBILITY_PC.public,
        serviceOrder: 0,
        brandName: "",
        lang: "",

        aboutTab: "",
        activationTab: "",
        priceTab: "",
    });

    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    useEffect(() => {
        let newFormData = new FormData();
        newFormData.append("file", file);
        setFormData(newFormData)
    }, [file]);


    useEffect(() => {
        let newFormData2 = new FormData();
        newFormData2.append("file", icon);
        setFormData2(newFormData2)
    }, [icon]);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (id !== "add") {
                getService(id).then(res => {
                    setData(res);
                })
            }
        }
        return () => mounted = false;
    }, [id]);

    const handleSubmit = () => {
        if (!(
            Boolean(data.title) &&
            Boolean(data.lang) &&
            Boolean(data.brandName)
        )) {
            // setValidate(true);
            toast.error("Некоторые поля должны быть заполнены. Заголовок, Язык, Название бренда", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            if (file && !icon) {
                uploadFile(formData).then(res => {
                    if (isAdd) {
                        addService({
                            ...data,
                            imageLink: res.url,
                        }).then(() => {
                            history.push("/services");
                            toast.success("Успешно добавлено", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                    } else {
                        editService({
                            ...data,
                            imageLink: res.url,
                        }).then(() => {
                            history.push("/services");
                            toast.success("Успешно изменено", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                    }
                })
            } else if (!file && icon) {
                uploadFile(formData2).then(res => {
                    if (isAdd) {
                        addService({
                            ...data,
                            iconLink: res.url,
                        }).then(() => {
                            history.push("/services");
                            toast.success("Успешно добавлено", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                    } else {
                        editService({
                            ...data,
                            iconLink: res.url
                        }).then(() => {
                            history.push("/services");
                            toast.success("Успешно изменено", {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        })
                    }
                })
            } else if (file && icon) {
                uploadFile(formData).then(res => {
                    uploadFile(formData2).then(r => {
                        if (isAdd) {
                            addService({
                                ...data,
                                imageLink: res.url,
                                iconLink: r.url,
                            }).then(() => {
                                history.push("/services");
                                toast.success("Успешно добавлено", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            })
                        } else {
                            editService({
                                ...data,
                                imageLink: res.url,
                                iconLink: r.url
                            }).then(() => {
                                history.push("/services");
                                toast.success("Успешно изменено", {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            })
                        }
                    })
                })
            } else {
                if (isAdd) {
                    addService(data).then(() => {
                        history.push("/services");
                        toast.success("Успешно добавлено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                } else {
                    editService(data).then(() => {
                        history.push("/services");
                        toast.success("Успешно изменено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
                }
            }

        }
    };
    return (
        <div>
            <Title
                title={isAdd ? "Добавить новое" : "Изменить"}
                base={props.base}
            />
            {(!isAdd && data.title !== "") || isAdd ? (

                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-body">
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    value={data.title}
                                    placeholder="Заголовок"
                                    onChange={(e) => handleChange("title", e.target.value)}
                                />
                               {data.brandName !== BRANDS.etc ? <textarea
                                    className="form-control form-control-lg mb-3"
                                    style={{minHeight: 125}}
                                    value={data.description}
                                    placeholder="Описание"
                                    onChange={(e) => handleChange("description", e.target.value)}
                                /> : (
                                   <input
                                       className="form-control form-control-lg mb-3"
                                       type="text"
                                       value={data.description}
                                       placeholder="Ссылка"
                                       onChange={(e) => handleChange("description", e.target.value)}
                                   />
                               )}
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="number"
                                    value={data.serviceOrder}
                                    placeholder="Порядок"
                                    onChange={(e) => handleChange("serviceOrder", e.target.value)}
                                />
                                {data && (data.imageLink || file) && (
                                    <div className="mb-4 img-overlay p-2 d-inline-block">
                                        <img src={file ? URL.createObjectURL(file) : data.imageLink} alt=""
                                             className="image-size"/>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="form-control form-control-lg mb-3"
                                            type="file"
                                            id="tariff_logo__input"
                                            accept=".jpg , .png"
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                        <label
                                            htmlFor="tariff_logo__input"
                                            className={"tariff_logo__label " + (file ? "bg-success" : "bg-primary")}
                                        >
                                            {file ? file?.name : "Выберите белую Икона"}
                                        </label>
                                    </div>
                                    {file && <button onClick={(e) => setFile(null)}
                                                     className="btn btn-danger p-2 file-remove-btn"><GrClose/></button>}
                                </div>
                                {file && <div>
                                    <p className="mb-2">
                                        Размер: <span
                                        className="text-primary">{(file?.size / 1e+6).toFixed(2)} MB </span>
                                    </p>
                                </div>}
                                {data && (data.iconLink || icon) && (
                                    <div className="mb-4">
                                        <div className="img-overlay p-2 d-inline-block">
                                            <img src={icon ? URL.createObjectURL(icon) : data.iconLink} alt=""
                                                 className="image-size"/>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="form-control form-control-lg mb-3"
                                            type="file"
                                            id="tariff_icon__input"
                                            style={{display: "none"}}
                                            accept=".jpg , .png, .svg"
                                            onChange={(e) => setIcon(e.target.files[0])}
                                        />
                                        <label
                                            htmlFor="tariff_icon__input"
                                            className={"tariff_logo__label " + (icon ? "bg-success" : "bg-primary")}
                                        >
                                            {icon ? icon?.name : "Выберите тёмную Икона"}
                                        </label>
                                    </div>
                                    {icon && <button onClick={(e) => setIcon(null)}
                                                     className="btn btn-danger p-2 file-remove-btn"><GrClose/></button>}
                                </div>
                                <p className="mb-1 font-weight-normal">Максималыный размер изображения
                                    5mb. <br/> Тип <b>.png, .jpg, .svg</b> поддерживает.</p>
                                {icon && <div>
                                    <p className="mb-2">
                                        Размер: <span
                                        className="text-primary">{(icon?.size / 1e+6).toFixed(2)} MB </span>
                                    </p>
                                </div>}
                            </div>
                        </div>
                       {data.brandName !== BRANDS.etc &&  <div className="card card-small mb-3">
                            <div className="card-body">
                                <ul className="nav nav-tabs ">
                                    <li className="nav-item">
                                        <button onClick={() => setTab(tabs.about)}
                                                className={"nav-link" + (tab === tabs.about ? " active" : "")}>Подробное
                                            описание
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={() => setTab(tabs.price)}
                                                className={"nav-link" + (tab === tabs.price ? " active" : "")}>Стоимось
                                            услуги
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={() => setTab(tabs.activation)}
                                                className={"nav-link" + (tab === tabs.activation ? " active" : "")}>Активация
                                            услуги
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className={"tab-pane fade" + (tab === tabs.about ? " show active" : "")}>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin]
                                            }}
                                            editor={ClassicEditor}
                                            data={data.aboutTab}
                                            onChange={(event, editor) => handleChange("aboutTab", editor.getData())}
                                        />
                                    </div>
                                    <div className={"tab-pane fade" + (tab === tabs.price ? " show active" : "")}>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin]
                                            }}
                                            editor={ClassicEditor}
                                            data={data.priceTab}
                                            onChange={(event, editor) => handleChange("priceTab", editor.getData())}
                                        />
                                    </div>
                                    <div className={"tab-pane fade" + (tab === tabs.activation ? " show active" : "")}>
                                        <CKEditor
                                            config={{
                                                extraPlugins: [uploadPlugin]
                                            }}
                                            editor={ClassicEditor}
                                            data={data.activationTab}
                                            onChange={(event, editor) => handleChange("activationTab", editor.getData())}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <div className="col-lg-3 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Язык</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        {objectToArrayForSelect(LANG_UPPER).map((item, key) => (
                                            <div key={key} className="custom-control custom-radio mb-1">
                                                <input
                                                    type="radio"
                                                    id={"service_lang_" + item.value}
                                                    name="lang"
                                                    className="custom-control-input"
                                                    value={item.value}
                                                    checked={data.lang === item.value}
                                                    onChange={(e) => handleChange("lang", e.target.value)}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={"service_lang_" + item.value}
                                                >{item.label}</label>
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Название бренда</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        {objectToArrayForSelect(BRANDS).map((item, key) => (
                                            <div key={key} className="custom-control custom-radio mb-1">
                                                <input
                                                    type="radio"
                                                    id={"service_brand_" + item.value}
                                                    name="brandName"
                                                    className="custom-control-input"
                                                    value={item.value}
                                                    checked={data.brandName === item.value}
                                                    onChange={(e) => handleChange("brandName", e.target.value)}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={"service_brand_" + item.value}
                                                >{item.label}</label>
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Видимость</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        {objectToArrayForSelect(data.brandName === BRANDS.tps ? VISIBILITY_PC : {public: VISIBILITY_PC.public}).map((item, key) => (
                                            <div key={key} className="custom-control custom-radio mb-1">
                                                <input
                                                    type="radio"
                                                    id={"service_visibility_" + item.value}
                                                    name="visibility"
                                                    className="custom-control-input"
                                                    value={item.value}
                                                    checked={data.visibility === item.value}
                                                    onChange={(e) => handleChange("visibility", e.target.value)}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={"service_visibility_" + item.value}
                                                >{item.label}</label>
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="card card-small mb-3">
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex px-3">
                                        <NavLink to="/payments" className="btn btn-outline-accent">
                                            Отмена
                                        </NavLink>
                                        <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                            {isAdd ? "Публиковать" : "Изменить"}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            ) : <BlockLoader/>}
        </div>
    );
}
