import React from "react";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from 'react-router-dom'

const TariffItem = (props) => {
  return (
    <div className="col-lg-4">
      <div className="card card-small card-post mb-4">
        <div className="card-body">
          <h5 className="card-title row no-gutters justify-content-between">
            <span>{props.name}</span>
            {/*<span style={{fontSize:"14px"}}>{props.type}</span>*/}
          </h5>
          <p className="card-text mb-2">
             <span style={{fontSize:"20px", color: "#000"}}>
              {props.price}
            </span> SUM
          </p>
          <p className="card-text text-muted mb-1">
            <span style={{fontSize:"16px", color: "#000"}}>
              {props.tasIxSpeed}
            </span> - TasIx
          </p>
          <p className="card-text text-muted mb-1">
            <span style={{fontSize:"16px", color: "#000"}}>
              {props?.dayTime}
            </span>
          </p>
          <p className="card-text text-muted mb-1">
            <span style={{fontSize:"16px", color: "#000"}}>
              {props?.nightTime}
            </span>
          </p>
        </div>
        <div className="card-footer border-top d-flex">
          <div className="card-post__author d-flex">
            <div className="d-flex flex-column justify-content-center">
              <span className="card-post__author-name">
                {props.type}
              </span>
              <small className="text-muted" title="Дата добавления">
                { props.tariffServiceTypeEnum}
              </small>
            </div>
          </div>
          <div className="my-auto ml-auto">
            <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/tariff/item/"+props.id}>
              <FiEdit2 className="mr-1"/>
              Изменить
            </NavLink>
            <button onClick={()=> props.deleteItem(props.id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >
              <AiOutlineDelete className="mr-1"/>
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TariffItem