import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import Select from "../../ui/select/Select";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {LANG_UPPER, VISIBILITY_PC} from "../../../constants/GLOBAL";
import {deleteEtService, getEtcServices} from "../services/ServiceActions";
import BlockLoader from "../../ui/loader/BlockLoader";
import EtServiceItem from "./EtServicesItem"
import {toast} from "react-toastify";

export function EtServicesList(props) {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        visibility: VISIBILITY_PC.public
    });
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState(null);
    const [reRender, setRender] = useState(false);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setLoading(true);
            getEtcServices(filter).then(res => {
                setLoading(false);
                setList(res)
            });
        }
        return () => mounted = false;
    }, [filter, reRender]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const handleDelete = (id) => {
        setLoading(true)
        deleteEtService(id).then(() => {
            setRender(!reRender);
            toast.success("Удалено");
        })
    }


    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={"/etc-services/add"}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="service_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="service_VISIBILITY_PC"
                                extraClassForSelect="mini"
                                label="Brand"
                                values={objectToArrayForSelect(VISIBILITY_PC)}
                                filterName="visibility"
                                activeValue={filter.visibility}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            {isLoading ? <BlockLoader/> : (
                <>

                    <div className="row">
                        {list && list.sort((a,b) => b.serviceOrder - a.serviceOrder).map((item, key) => (
                            <EtServiceItem
                                data={item}
                                key={key}
                                onDelete={handleDelete}
                            />
                        ))}
                    </div>

                    {list && list.length === 0 && (
                        <div className="font-weight-bold"><h4>Пусто</h4></div>
                    )}
                </>
            )}
        </div>
    );
}

