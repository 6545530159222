import React, {useState} from "react";
import {api} from "../../../../../api/api";
import {toast} from "react-toastify";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from "react-router-dom";

export default function VacancyItem({data}) {
    const [deleted, setDeleted] = useState();
    const handleDelete = (id) => {
        api.delete("vacancy/etc/delete/" + id)
            .then(response => {
                if (response.status) {
                    toast.success("Удалено");
                    setDeleted(true);
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    };

    return (
        <div className={`faq_item mb-4 py-2 px-4 bg-white rounded ${deleted && "deleted"}`}>
            <h4 className={`font-weight-bold`}>{data?.jobName}</h4>
            <h5 className={`font-weight-bold`}>ТребованияиОбязанности:</h5>
            <ul>
                {data?.requirements?.split('\n',)
                    .map(((requirement, i) => <li key={i}>{requirement}</li>))}
            </ul>
            <h5 className={`font-weight-bold`}>Знание компьютерных программ:</h5>
            <p>{data?.computerSkills}</p>
            <h5 className={`font-weight-bold`}>Образование:</h5>
            <p>{data?.education}</p>
            <h5 className={`font-weight-bold`}>Позиция: </h5>
            <p>{data?.position}</p>
            <h5 className={`font-weight-bold`}>Дополнительные требования: </h5>
            <ul>
                {data?.extraRequirements?.split('\n',)
                    .map((requirement, i) => <li key={i}>{requirement}</li>)}
            </ul>
            <h5 className={`font-weight-bold`}>Контакты: </h5>
            <p>
                <a href={`mailto:${data?.email}`}>{data?.email}</a>,
                {data?.employer} тел.: {data?.phoneNumber}
            </p>
            <div className="my-auto ml-auto d-flex justify-content-end">
                <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/vacancy/item/"+data?.id}>
                    <FiEdit2 className="mr-1"/>
                    Edit
                </NavLink>
                <button onClick={()=> handleDelete(data.id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >
                    <AiOutlineDelete className="mr-1"/>
                    Delete
                </button>
            </div>
        </div>
    )
}
