import React, {useEffect, useState} from 'react';
import AgreeModal from "../ui/agreeModal/AgreeModal";
import {api} from "../../api/api";
import {toast} from "react-toastify";
import {setToken} from "../../store/main/mainActions";
import Cookies from "universal-cookie";
import {useHistory} from 'react-router-dom'
import {useDispatch} from "react-redux";

const cookies = new Cookies();

function User() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState("");
    const [login, setLogin] = useState("");
    useEffect(()=>{
        const useId = localStorage.getItem("userId");
        setId(useId)
    }, [id] );
     useEffect(()=>{
         const userLogin = localStorage.getItem("userLogin");
         setLogin(userLogin)
     }, [login]
         );
    const onDeleteAccount = () => {
        api.delete("user/delete/" + id).then(() => {
            toast.success("Успешно Удалено");
            dispatch(setToken(null));
            cookies.remove("token", {path: '/'});
            setTimeout(() => {
                history.replace("/login")
            }, 3000)
        }).catch((error) => {
            console.log(error)
        })
    };
    return (
        <div className="user_acc" title={login}>
            <span>{login?.substring(0, 1)}</span>
            <div className="user_actions">
                <div className="user_actions__item border-bottom" onClick={() => history.push("/user/update")}><span>Изменить</span></div>
                <div className="user_actions__item" onClick={() => setShowModal(true)}>Удалить</div>
            </div>
            {showModal && <AgreeModal body={`вы уверены что хотите удалить этот аккаунт`} title={login}
                                      onSubmit={onDeleteAccount} onCancel={() => setShowModal(false)}/>}
        </div>
    );
}

export default User;