import React from "react";
import MenuItem from "./MenuItem";
import MENU from "../../../constants/MENU";

const Menu = () => {
  return(
    <ul className="nav flex-column">
      {MENU.map((item, key)=> (
        <MenuItem
          key={key}
          link={item.link}
          title={item.title}
          icon={<item.icon/>}
        />
      ))}
    </ul>
  )
}
export default Menu