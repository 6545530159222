import React from "react";

const Select = (props) => {
  return(
    <div>
    {props.label && <label htmlFor={props.id} className="small-label mb-1">{props.label}</label>}
      <select
        onChange={(e) => props.onChange(props.filterName, e.target.value)}
        id={props.id}
        value={props.activeValue}
        className={"form-control "+props.extraClassForSelect}
      >
        {props.values.map((item, key)=>(
          <option
            key={key}
            value={item.value}
          >{item.label}</option>
        ))}
      </select>
    </div>
  )
}
export default Select