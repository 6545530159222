import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import NewsItem from "./components/list/NewsItem";
import {LANG, BRANDS, VISIBILITY_PC} from "../../../constants/GLOBAL";
import {findInObject, objectToArrayForSelect} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import ROUTES from "../../../constants/ROUTES";
import BlockLoader from "../../ui/loader/BlockLoader";
import {deleteItem, getCats, getList} from "./actions/newsActions";
import {toast} from "react-toastify";

const NewsList = (props) => {
    const [filter, setFilter] = useState({
        lang: LANG.ru,
        brandName: BRANDS.tps,
        visibility: VISIBILITY_PC.public,
        pageNumber: "1",
        pageSize: "50"
    });
    const [news, setNews] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [cats, setCats] = useState(null);
    const [elements, setElements] = useState({
        pageNumber: [filter.pageNumber],
        total: null
    })


    useEffect(() => {
        let mounted = true;
        getList(filter).then(data => {
            if (mounted) {
                setElements({
                    size: data.size,
                    pageNumber: Array.from(new Array(data.totalPages).keys()),
                    total: data.totalElements
                })
                setNews(data.content)
                setLoading(false)
            }
        })
        getCats(filter.lang).then(data => {
            if (mounted) {
                setCats(data)
            }
        })
        return () => mounted = false;
    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        setNews(null)
        setLoading(true)
    }

    const handleDelete = (id) => {
        deleteItem(id).then(() => {
            setLoading(true)
            setNews(null)
            toast.success("Удалено");
            getList(filter).then(data => {
                setNews(data.content)
                setLoading(false)
            })
        })
    }

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.news_add}
            >
                <form>
                    <div className="d-flex align-items-center">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_brand"
                                extraClassForSelect="mini"
                                label="Brand"
                                values={objectToArrayForSelect(BRANDS)}
                                filterName="brandName"
                                activeValue={filter.brandName}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-5 mb-0">
                            <Select
                                id="news_visibility"
                                extraClassForSelect="mini"
                                label="Visibility"
                                values={objectToArrayForSelect(VISIBILITY_PC)}
                                filterName="visibility"
                                activeValue={filter.visibility}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <label className="small-label mb-1">Page #</label>
                            <select
                                onChange={(e) => handleFilterChange("pageNumber", (e.target.value ? e.target.value : 1))}
                                value={filter.pageNumber}
                                className="form-control"
                                >
                                {elements.pageNumber.map((item, key)=>(
                                    <option
                                        key={key}
                                        value={item+1}
                                    >{item+1}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <label htmlFor="pageSize" className="small-label mb-1">Size</label>
                            <input
                                className="form-control form-control"
                                type="text"
                                style={{width: 50}}
                                maxLength={2}
                                value={filter.pageSize}
                                id="pageSize"
                                onChange={(e) => handleFilterChange("pageSize", (e.target.value && +e.target.value < +elements.total ? e.target.value : 6))}
                            />
                        </div>
                        <div className="form-group mb-0">
                            <label className="small-label mb-1">Total:</label>
                            <div className="info-input">
                                {elements && elements.total}
                            </div>
                        </div>
                    </div>
                </form>
            </Title>
            <div className="row">
                {news && cats && news.map((item, key) => (
                    <NewsItem
                        key={key}
                        id={item.id}
                        top={item.top}
                        title={item.title}
                        shortDescription={item.shortDescription}
                        category={item.categoryId && findInObject(cats, "categoryId", item.categoryId, "name")}
                        createdDate={item.createdDate}
                        deleteItem={() => handleDelete(item.id)}
                    />
                ))}
            </div>
            {isLoading && (
                <BlockLoader/>
            )}
            {news && news.length === 0 && (
                <div className="font-weight-bold"><h4>Пусто</h4></div>
            )}
        </div>
    )
}

export default NewsList