import React, {useState} from 'react';
import {AiOutlineInstagram, AiOutlineFacebook, FaTelegram, AiOutlineTwitter, AiOutlineDelete} from "react-icons/all";
import {api} from "../../../api/api";
import {toast} from "react-toastify";

function CompanySettingsItem({data}) {
    const [deleted, setDeleted] = useState();
    const handleDelete = (id) => {
        api.delete("settings/delete/" + id)
            .then(response => {
                if (response.status) {
                    toast.success("Удалено");
                    setDeleted(true);
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    };
    return (
        <div className={`col-lg-6 rounded ${deleted && "deleted"}`}>
            <div className="mb-4 bg-white rounded p-3">
                {data && (<div className="card-body p-0">
                    <div className="border-bottom py-3">
                        <span>Тймный логотип: </span>
                        <div className="img-overlay d-inline-block">
                        <img src={data?.logoDark} alt={data?.logoDark} style={{
                            maxHeight: "33px"
                        }}/>
                        </div>
                    </div>
                    <div className="border-bottom py-3">
                        <span>Белый логотип: </span>
                        <div className="img-overlay d-inline-block">
                        <img src={data?.logoWhite} alt={data?.logoWhite} style={{
                            maxHeight: "33px"
                        }}/>
                        </div>
                    </div>
                    <div className="border-bottom py-3">
                        <span>Телефон номер: </span>
                        <span>{data?.phoneNumber}</span>
                    </div>
                    <a href={data?.socials.facebook} className="border-bottom py-3 d-block">
                        <span><AiOutlineFacebook className="icon-primary"/></span>
                        <span>{data?.socials.facebook}</span>
                    </a>
                    <a href={data?.socials?.instagram} className="border-bottom py-3 d-block">
                        <span><AiOutlineInstagram className="icon-primary"/></span>
                        <span>{data?.socials?.instagram}</span>
                    </a>
                    <a href={data.socials.telegram} className="border-bottom py-3 d-block">
                        <span><FaTelegram className="icon-primary"/></span>
                        <span>{data?.socials?.telegram}</span>
                    </a>
                    <a href={data.socials?.twitter} className="border-bottom py-3 d-block">
                        <span><AiOutlineTwitter className="icon-primary"/> </span>
                        <span>{data.socials?.twitter}</span>
                    </a>
                    <div className="py-2 d-flex justify-content-end">
                        <button onClick={() => handleDelete(data?.id)}
                                className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase">
                            <AiOutlineDelete className="mr-1"/>
                            Delete
                        </button>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default CompanySettingsItem;