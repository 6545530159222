import {
  BiNews,
  FaClipboardList,
  ImLocation2,
  MdQuestionAnswer,
  AiOutlineContacts,
  AiOutlineSearch,
  AiOutlineSchedule,
  RiNewspaperLine,
  MdPayment,
  IoMdHelpCircleOutline,
  MdSecurity,
  BiUserPlus,
  VscPreview,
  BiSupport,
  BiWifi,
} from "react-icons/all";

const MENU = [
  {
    link: "/news/all",
    title: "Новости",
    icon: BiNews,
  },
  {
    link: "/tariff/all",
    title: "Тарифы",
    icon: FaClipboardList,
  },
  {
    link: "/zone",
    title: "Зоны покрытие",
    icon: ImLocation2,
  },
  {
    link: "/faq",
    title: "FAQ",
    icon: MdQuestionAnswer,
  },
  {
    link: "/contacts",
    title: "Контакты",
    icon: AiOutlineContacts,
  },
  {
    link: "/vacancy",
    title: "Ваканции",
    icon: AiOutlineSearch,
  },
  {
    link: "/tender",
    title: "Тендер",
    icon: AiOutlineSchedule,
  },
  // {
  //   link: "/applications",
  //   title: "Заявки",
  //   icon: RiNewspaperLine
  // },
  {
    link: "/applications-tariff",
    title: "Заявки на тарифы",
    icon: RiNewspaperLine,
  },
  {
    link: "/applications-technique",
    title: "Заявки на технические работы",
    icon: RiNewspaperLine,
  },
  {
    link: "/payments",
    title: "Способ оплаты",
    icon: MdPayment,
  },
  {
    link: "/services",
    title: "Услуги",
    icon: IoMdHelpCircleOutline,
  },
  {
    link: "/public-offer",
    title: "Публичная оферта",
    icon: MdSecurity,
  },
  {
    link: "/company-settings",
    title: "Контакты Компании",
    icon: AiOutlineContacts,
  },
  {
    link: "/register-user",
    title: "Добавить нового пользователя",
    icon: BiUserPlus,
  },
  {
    link: "/etc-services",
    title: "ETC Услуги",
    icon: IoMdHelpCircleOutline,
  },
  {
    link: "/support-categories",
    title: "Категории центра поддержки",
    icon: BiSupport,
  },
  {
    link: "/company-routers",
    title: "Роутеры",
    icon: BiWifi,
  },
  {
    link: "/banners",
    title: "Баннеры",
    icon: VscPreview,
  },
];
export default MENU;
