import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import {LANG_UPPER} from "../../../constants/GLOBAL";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import BlockLoader from "../../ui/loader/BlockLoader";
import PublicOfferItem from "./PublicOfferItem";
import {api} from "../../../api/api";
import ROUTES from "../../../constants/ROUTES";

const PublicOfferList = (props) => {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru
    });
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [tab, setTab] = useState(null);

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setLoading(true);
            api.get("public-offer/list" + objectToUrl(filter)).then(res => {
                if(res && res.payload){
                    setLoading(false);
                    setData(res.payload)
                }
            });
        }
        return () => mounted = false;
    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    }

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.public_offer_add}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>

            <div className="row">

                <div className="col-md-8 col-12 order-md-first">
                    <div className="list-group">
                        {data && data.map((item, i)=> (
                            <PublicOfferItem
                                key={i}
                                data={item}
                                tab={tab}
                                onChangeTab={setTab}
                            />
                        ))}
                    </div>

                    {data && data.length === 0 && (
                        <div className="font-weight-bold"><h4>Пусто</h4></div>
                    )}
                    {isLoading && (
                        <BlockLoader/>
                    )}
                </div>
            </div>

        </div>
    )
}

export default PublicOfferList