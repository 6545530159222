import React, {useState} from 'react';
import Title from "../../title/Title";
import {NavLink, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {api} from "../../../api/api";

export default function AddSupportCategories(props) {
    const history = useHistory()

    const [data, setData] = useState({
        title: "",
        link: "",
    });


    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };

    const handleSubmit = () =>{
        if(!(
            Boolean(data.link) ||
            Boolean(data.title)
        )){
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        }else{
            api.post("/support/create", data).then(()=> {
                history.push("/support-categories")
                toast.success("Успешно добавлено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
        }
    }

    return (
        <div>
            <Title title={props.title} base={props.base}/>
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.data}
                                placeholder="Имя"
                                onChange={(e) => handleChange("title", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.link}
                                placeholder="Ссылка"
                                onChange={(e) => handleChange("link", e.target.value)}
                            />
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/faq" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button onClick={handleSubmit} className="btn btn-success ml-auto" >
                                        Публиковать
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

