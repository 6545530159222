import React, {useState} from 'react';
import Title from "../../title/Title";
import {toast} from "react-toastify";
import {api} from "../../../api/api";
import { useHistory} from "react-router-dom";
export function UpdateUser(props) {
    const history = useHistory();

    const [data, setData] = useState({
        email: window.localStorage.getItem("userLogin"),
        newPassword: "",
        prevPassword: "",
        id: window.localStorage.getItem("userId")
    });
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };
    const handleSubmit = () => {
        if (!(
            Boolean(data.email) &&
            Boolean(data.newPassword) &&
            Boolean(data.prevPassword)
        )) {
            toast.error("Некоторые поля должны быть заполнены. Логин, Пароль", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            api.put("/user/update", data).then((r) => {
                if (r.status === "OK") {
                    toast.success(`Успешно изменено`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    history.push("/news/all");
                    localStorage.setItem("userLogin", r.payload.email)
                }
            }).catch(error => {
                if (error.status === 403) {
                    toast.error(`${error.data.error}`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
                else if(error.data.status === "DUPLICATE_ENTITY") {
                    toast.error("Пользователь уже существует", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
        }
    };


    return (
        <div>
                <Title
                    title={props.title}
                    base={props.base}
                >

                </Title>
                <div className="row">
                    <div className="col-lg-9 col-md-12">
                        <div className="card card-small mb-3">
                            <div className="card-body">
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    placeholder="Логин"
                                    value={data.email}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                />
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="password"
                                    value={data.prevPassword}
                                    placeholder="Существующий Пароль"
                                    onChange={(e) => handleChange("prevPassword", e.target.value)}
                                />
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="password"
                                    placeholder="Новый Пароль"
                                    value={data.newPassword}
                                    onChange={(e) => handleChange("newPassword", e.target.value)}
                                />
                                <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

