import React from "react";

import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";

export default function MainContainer(props){
    if(!props.isAuthed) return props.children

    return(
      <div className="container-fluid">
        <div className="row">
          <Sidebar/>
          <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
            <Header/>
            <div className="main-content-container container-fluid px-4">
              {props.children}
            </div>
          </main>
        </div>
      </div>
    )
}