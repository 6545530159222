import React from "react";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {trimString} from "../../../utils/helpers";

const PaymentItem = (props) => {

    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card card-small card-post h-100">
                {props.data && props.data.imageLink && (
                    <div className="card-post__image">
                        <img src={props.data.imageLink} alt="Payment" />
                    </div>
                )}
                <div className="card-body">
                    <h5 className="card-title">
                        {props.data && props.data.name}
                    </h5>
                    <p className="card-text">
                        {trimString(props.data && props.data.info, 130)}
                    </p>
                </div>
                <div className="card-footer text-muted border-top py-3">
                    <div className="list-group-actions d-flex justify-content-between">
                        <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/payment/"+props.data.id}>
                            <FiEdit2 className="mr-1"/>
                            Изменить
                        </NavLink>
                        <button
                            className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
                            onClick={() => props.onDelete(props.data.id)}
                        >
                            <AiOutlineDelete className="mr-1"/>
                            Удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentItem