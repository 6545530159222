import React from "react";
import {FiEdit2, AiOutlineDelete} from "react-icons/all";
import {NavLink} from 'react-router-dom'
import {trimString} from "../../../../../utils/helpers";

const NewsItem = (props) => {

  return (
    <div className="col-lg-4">
      <div className="card card-small card-post mb-4">
        <div className="card-body">
          <h5 className="card-title d-flex justify-content-between">
            <span>{props.title}</span>
            <span style={{fontSize: "9px", color: "#00f", textTransform: "uppercase"}}>{props.top ? "главные" : ""}</span>
          </h5>
          <p className="card-text text-muted trim-body">
            {trimString(props.shortDescription, 70)}
          </p>
        </div>
        <div className="card-footer border-top d-flex">
          <div className="card-post__author d-flex">
            <div className="d-flex flex-column justify-content-center">
              <span className="card-post__author-name">{props.category}</span>
              <small className="text-muted" title="Дата добавления">
                { props.createdDate && new Date(Number(props.createdDate)).toLocaleDateString()}
              </small>
            </div>
          </div>
          <div className="my-auto ml-auto">
            <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/news/item/"+props.id}>
              <FiEdit2 className="mr-1"/>
              Изменить
            </NavLink>
            <button onClick={()=> props.deleteItem(props.id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >
              <AiOutlineDelete className="mr-1"/>
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsItem