import React, {useState} from "react";
import {api} from "../../../api/api";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";

export default function CompanyRouterItem({data}) {
    const [deleted, setDeleted] = useState(false);
    const handleDelete = (id) => {
        api.delete("company-router/delete/" + id)
            .then(response => {
                if (response.status) {
                    toast.success("Удалено");
                    setDeleted(true);
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    };


    return (
        <div className={`col-lg-6 col-md-6 col-sm-12 mb-4 ${deleted && "deleted"}`}>
            <div className="card card-body flex-md-row">
                {data && data.imageLink && (
                    <div className="flex-column w-100">
                        <img src={data.imageLink} alt="Company Routers icon"/>
                    </div>
                )}
                <div className="card-body dropdown-menu-right flex-column">
                    <div className="row">
                        <div className="col-md-12">
                            <h5 className="card-title">
                                {data && data.title}
                            </h5>
                            <p className="card-text">
                                {data && data.description}
                            </p>
                            <span className="card-title">{data && data.model}</span>
                            <span className="card-text float-right">
                                {data && data.price}
                            </span>
                        </div>
                    </div>
                    <div className="text-muted border-top py-5">
                        <div className="list-group-actions d-flex justify-content-between">
                            <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"
                                     to={"/company-routers/" + data.id}>
                                <FiEdit2 className="mr-1"/>
                                Изменить
                            </NavLink>
                            <button
                                onClick={() => handleDelete(data.id)}
                                className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
                            >
                                <AiOutlineDelete className="mr-1"/>
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
