import React from "react";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";

const CategoryItem = (props) => {

    return (
        <div className="list-group-item list-group-item-action">
            <div className="list-group-title">
                {props.title}
            </div>
            <div className="list-group-actions">
                <button
                    className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"
                    onClick={() => props.onEdit({
                        id: props.id,
                        name: props.title
                    })}
                >
                    <FiEdit2 className="mr-1"/>
                    Изменить
                </button>
                <button
                    className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
                    onClick={() => props.onDelete({
                        id: props.id,
                        name: props.title
                    })}
                >
                    <AiOutlineDelete className="mr-1"/>
                    Удалить
                </button>
            </div>
        </div>
    )
}

export default CategoryItem