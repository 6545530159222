import Title from "../../title/Title";
import React, {useEffect, useState} from "react";
import {api} from "../../../api/api";
import {LANG_UPPER, BRANDS_FOR_TARIFF, VISIBILITY} from "../../../constants/GLOBAL";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import FaqsItem from "./components/FaqsItem";
import ROUTES from "../../../constants/ROUTES";
import BlockLoader from "../../ui/loader/BlockLoader";

const FaqList = (props) => {

  const [filter, setFilter] = useState({
    companyNameEnum: BRANDS_FOR_TARIFF.tps,
    faqVisibilityEnum: VISIBILITY.public,
    lang: LANG_UPPER.ru
  });

  const [faq, setFaq] = useState(null);
  const [isLoading, setLoading] = useState(false);


  useEffect(()=>{
    let mounted = true;
    if(mounted){
      setLoading(true)
      api.get("/faq/list"+ objectToUrl(filter)).then(res => {
        if (res && res.payload) {
          setLoading(false)
          setFaq(res.payload);
        }
      })
    }
    return () => mounted = false;
  }, [filter]);


  const handleFilterChange = (name, value) => {
    setFilter({
      ...filter,
      [name]: value
    });
    setFaq(null)
  };

  return (
    <div>
      <Title
        title={props.title}
        base={props.base}
        addNewButtonUrl={ROUTES.faq_add}
      >
        <form>
          <div className="form-row">
            <div className="form-group mr-3 mb-0">
              <Select
                id="faq_language"
                extraClassForSelect="mini"
                label="Language"
                values={objectToArrayForSelect(LANG_UPPER)}
                filterName="lang"
                activeValue={filter.lang}
                onChange={handleFilterChange}
              />
            </div>
            <div className="form-group mr-3 mb-0">
              <Select
                id="faq_brand"
                extraClassForSelect="mini"
                label="Brand"
                values={objectToArrayForSelect(BRANDS_FOR_TARIFF)}
                filterName="companyNameEnum"
                activeValue={filter.companyNameEnum}
                onChange={handleFilterChange}
              />
            </div>
            <div className="form-group mr-3 mb-0">
              <Select
                  id="faq_visibility"
                  extraClassForSelect="mini"
                  label="Visibility"
                  values={objectToArrayForSelect(VISIBILITY)}
                  filterName="faqVisibilityEnum"
                  activeValue={filter.faqVisibilityEnum}
                  onChange={handleFilterChange}
              />
            </div>
          </div>
        </form>
      </Title>
      <div>
        {
          faq && faq.map((item, key) => {
            return (
                <FaqsItem
                    key={key}
                    id={item.id}
                    answer={item.answer}
                    question={item.question}
                    category={item.faqCategoryEnum}
                />
            )
          })
        }
      </div>
      {isLoading && (
          <BlockLoader/>
      )}
      <div>
        {faq && faq.length === 0 && (
            <div className="font-weight-bold"><h4>Пусто</h4></div>
        )}
      </div>

    </div>
  )
}

export default FaqList