import React from "react";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from "react-router-dom";
import {trimString} from "../../../utils/helpers";

const EtServiceItem = (props) => {


    return (
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card card-small card-post h-100">
                {props.data && props.data.bannerMedia && (
                    <div className="card-post__image ">
                        <img src={props.data.bannerMedia} className="max-height" alt="Services" />
                    </div>
                )}
                <div className="card-body">

                            <div className="row">
                                <div className="col-md-9">
                                    <span>{props.data && props.data.name}</span>
                                    <h5 className="card-title">
                                        {props.data && props.data.bannerTitle}
                                    </h5>
                                    <p className="card-text">
                                        {trimString(props.data && props.data.bannerDescription, 130)}
                                    </p>
                                </div>
                            </div>
                </div>
                <div className="card-footer text-muted border-top py-3">
                    <div className="list-group-actions d-flex justify-content-between">
                        <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/etc-services/"+props.data.id}>
                            <FiEdit2 className="mr-1"/>
                            Изменить
                        </NavLink>
                        <button
                            onClick={() => props.onDelete(props.data.id)}
                            className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"
                        >
                            <AiOutlineDelete className="mr-1"/>
                            Удалить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EtServiceItem