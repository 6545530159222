import * as t from "./mainTypes";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  isLoading: false,
  token: cookies.get("token") ? cookies.get("token") : null
};

const main = (state = initialState, action) => {
  switch(action.type){
    case t.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case t.SET_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    default:
      return {...state}
  }
}

export default main;