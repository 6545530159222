import {api} from "../../../api/api";

export function getContacts(id) {
    return api.get("company-contact/" + id).then(res => {
        return res && res.payload
    })
}
export function editContacts(data){
    return api.put("company-contact/update", data).then(res=> {
        return res && res.payload
    })
}