import React from 'react';
import {NavLink} from "react-router-dom";
import {FiEdit2} from "react-icons/all";

function BannerItem(props) {
    return (
        <div className="banner_item">
            <div className="banner_media">
                {props.media ? (props.media.includes(".mp4") ? <video src={props.media} controls /> :
                    <img src={props.media} alt="media"/>) : (
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <h2>Медиафайл отсуствует</h2>
                    </div>
                )}
            </div>
            <div className="banner_content">
                <h1>{props.title}</h1>
                <p>{props.desc}</p>
            </div>
            <div className="my-auto ml-auto d-flex justify-content-end">
                <NavLink className="btn btn-md btn-success pl-2 pr-2 text-uppercase mr-2"
                         to={props.isTariff ? "tariff/item/" + props.id : "news/item/"+ props.id}>
                    <FiEdit2 className="mr-1"/>
                    Edit
                </NavLink>
                {/*<button onClick={()=> handleDelete(id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >*/}
                {/*    <AiOutlineDelete className="mr-1"/>*/}
                {/*    Delete*/}
                {/*</button>*/}
            </div>
        </div>
    );
}

export default BannerItem;



/*
* brandName: "TPS"
categoryId: 26
content: "Технические работы!В связи с плановыми техническими работами на сети с 00 : 00 (13.01.2021г.) до 08 : 00 (13.01.2021г.) (тшк).Будут проводиться технические работы для улучшения качества предоставляемых услуг.В этот период возможны ухудшения качества и прерывания предоставляемых услуг телекоммуникаций.Приносим извинения за причиненные неудобства!"
createdBy: null
createdDate: null
id: 101
imageStorageList: []
lang: "ru"
modifiedBy: null
modifiedDate: null
shortDescription: "В связи с плановыми техническими работами на сети с 00 : 00 (13.01.2021г.) до 08 : 00 (13.01.2021г.) (тшк). Будут проводиться технические работы для улучшения качества предоставляемых услуг."
status: null
title: "Технические работы!"
top: false
type: "banner"
visibility: "PUBLIC"
* */