import React from "react";
import Cookies from "universal-cookie";
import {useHistory} from 'react-router-dom'
import {useDispatch} from "react-redux";
import {setToken} from "../../store/main/mainActions";
import User from "./User";


const cookies = new Cookies();

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();



  const logout = () => {
    dispatch(setToken(null));
    cookies.remove("token", { path: '/' });
    history.replace("/login")
  };



  return(
    <div className="main-navbar sticky-top bg-white">
      <nav className="navbar navbar-light flex-md-nowrap pl-3 pr-3 align-items-center justify-content-end">
        <User />
        <button className="btn btn-outline-danger" onClick={logout}>
          Выйти
        </button>
      </nav>
    </div>
  )
}

export default Header