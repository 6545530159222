import React, { useState } from "react";
import Title from "../../title/Title";
import { toast } from "react-toastify";
import { api } from "../../../api/api";
import { useHistory } from "react-router-dom";
export function RegisterUser(props) {
  const history = useHistory();
  const userRole = localStorage.getItem("role");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (!(Boolean(data.email) && Boolean(data.password))) {
      toast.error("Некоторые поля должны быть заполнены. Логин, Пароль", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      api
        .post("/register-user", data)
        .then((r) => {
          if (r.status === "OK") {
            toast.success(`Успешно добавлено`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            history.push("/news/all");
          }
        })
        .catch((error) => {
          if (error.status === 403) {
            toast.error(`${error.data.error}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else if (error.data.status === "DUPLICATE_ENTITY") {
            toast.error("Пользователь уже существует", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };

  return (
    <div>
      {userRole.includes("ADMIN") ? (
        <>
          <Title title={props.title} base={props.base}></Title>
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="card card-small mb-3">
                <div className="card-body">
                  <input
                    className="form-control form-control-lg mb-3"
                    type="text"
                    placeholder="Логин"
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                  <input
                    className="form-control form-control-lg mb-3"
                    type="password"
                    placeholder="Пароль"
                    onChange={(e) => handleChange("password", e.target.value)}
                  />
                  <button
                    className="btn btn-success ml-auto"
                    onClick={handleSubmit}
                  >
                    Добавить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center py-4">
          <h4>
            Вы не можете добавить нового пользователя, так как, вы не являетесь
            админом
          </h4>
        </div>
      )}
    </div>
  );
}
