import {api} from "../../../../api/api";

// Cities
export function getCities() {
    return api.get("coverage_zones/cities/list").then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function addCity(name) {
    return api.post("coverage_zones/cities?cityName="+name).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function editCity(name, id) {
    let body = {
        id: id,
        name: name
    }
    return api.put("coverage_zones/cities/", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function deleteCity(id) {
    return api.delete("coverage_zones/cities/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

// Districts
export function getDistricts(id) {
    return api.get("coverage_zones/districts/list/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
export function addDistrict(name, id) {
    let body = {
        cityId: id,
        districtName: name
    }
    return api.post("coverage_zones/districts", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
export function editDistrict(name, id, parentId) {
    let body = {
        id: id,
        cityId: parentId,
        name: name
    }
    return api.put("coverage_zones/districts/", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function deleteDistrict(id) {
    return api.delete("coverage_zones/districts/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
// Streets
export function getStreets(id) {
    return api.get("coverage_zones/streets/list/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function addStreet(name, id) {
    let body = {
        districtId: id,
        streetName: name,
        streetType: "ULICA"
    }
    return api.post("coverage_zones/streets", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function editStreet(name, id, parentId) {
    let body = {
        id: id,
        districtId: parentId,
        name: name
    }
    return api.put("coverage_zones/streets/", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
export function deleteStreet(id) {
    return api.delete("coverage_zones/streets/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
// Buildings
export function getBuildings(id) {
    return api.get("coverage_zones/buildings/list/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function addBuilding(name, id) {
    let body = {
        streetId: id,
        buildingName: name
    }
    return api.post("coverage_zones/buildings", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}
export function editBuilding(name, id, parentId) {
    let body = {
        id: id,
        streetId: parentId,
        name: name
    }
    return api.put("coverage_zones/buildings/", body).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}

export function deleteBuilding(id) {
    return api.delete("coverage_zones/buildings/"+id).then(res => {
        if (res && res.payload) {
            return res && res.payload
        }
    })
}