import axios from 'axios';

import Cookies from 'universal-cookie';
import {toast} from "react-toastify";
const cookies = new Cookies();

const API_URL = "https://api.etc.uz/api/";
const API_VERSION = "v1/";

export const api = axios.create({
    baseURL: API_URL+API_VERSION
});

api.interceptors.request.use(function (config) {

    config.headers = {
        ...config.headers,
        Authorization: "Bearer "+cookies.get("token")
    };
    // you can also do other modification in config
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response
    .use(response => {
        if(response && response.data && response.status === 200){
            return response.data;
        }
    },
error => {
    if(
        error.response
        && error.response.data
        && (
            error.response.status === 400
            || error.response.status === 401
        )
    ) {
        let err = error.response.data
        if(err.errors && err.errors.length){
            for(let i=0; err.errors.length > i; i++){
                toast.error(err.errors[i].errorMessage, {
                    toastId: "api_error_"+i
                });
            }
        }
    }
    return Promise.reject(error.response);
});
