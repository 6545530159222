import React, {useState} from "react";
import {api} from "../../../../../api/api";
import {toast} from "react-toastify";
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {NavLink} from "react-router-dom";

export default function TenderItem({data}) {
    const [deleted, setDeleted] = useState();
    const handleDelete = (id) => {
        api.delete("tender/delete/" + id)
            .then(response => {
                if (response.status) {
                    toast.success("Удалено");
                    setDeleted(true);
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    };

    return (
        <div className={`col-lg-6 rounded ${deleted && "deleted"}`}>
            <div className={`mb-4 bg-white rounded`}>
                <div className="card-body p-0">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Запрос, №</strong>
                                </td>
                                <td>
                                    {data.requestNumber}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Наименование закупки
                                </td>
                                <td>
                                    {data.purchaseName}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Способ закупок
                                </td>
                                <td>
                                    {data.purchaseMethod}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Заказчик
                                </td>
                                <td>
                                    {data.customer}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Организатор
                                </td>
                                <td>
                                    {data.organizer}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Дата публикации
                                </td>
                                <td>
                                    {data.publicationDate}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Дата начала приема заявок
                                </td>
                                <td>
                                    {data.applicationStartDate}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Дата окончания приема заявок
                                </td>
                                <td>
                                    {data.applicationDeadline}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Описание лота
                                </td>
                                <td>
                                    {data.lotDescription}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Количество
                                </td>
                                <td>
                                    {data.quantity}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Единица измерения
                                </td>
                                <td>
                                    {data.measurementUnit}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Срок поставки, до
                                </td>
                                <td>
                                    {data.deliveryTime}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Условия поставки
                                </td>
                                <td>
                                    {data.termsOfDelivery}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Вложения (документация по тендеру)
                                </td>
                                <td>
                                    {data.attachments}
                                </td>
                            </tr>
                            <tr>
                                <td>Контактная информация
                                </td>
                                <td>{data.phoneNumber} <br/><br/> Менеджер по работе с партнерами:<br/><br/>
                                    {data.manager}<br/><br/> email: {data.email}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="my-auto ml-auto d-flex justify-content-between">
                                        <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"
                                                 to={"/tender/item/" + data?.id}>
                                            <FiEdit2 className="mr-1"/>
                                            Edit
                                        </NavLink>
                                        <button onClick={() => handleDelete(data?.id)}
                                                className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase">
                                            <AiOutlineDelete className="mr-1"/>
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
