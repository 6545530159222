import {api} from "../../../../api/api";
import {objectToUrl} from "../../../../utils/helpers";

export function getList(filterObj) {
    return api.get("news/list" + objectToUrl(filterObj)).then(res => {
        return res && res.payload
    });
}
export function getItem(id) {
    return api.get("news/" + id).then(res => {
        return res && res.payload
    });
}
export function deleteItem(id) {
    return api.delete("news/" + id).then(res => {
        return res
    });
}


export function getCats(lang) {
    return api.get("news/category/list/" + lang).then(res => {
        return res && res.payload
    });
}
export function addCat(body) {
    return api.post("news/category/create", body).then(res => {
        return res && res.payload
    });
}
export function editCat(id, body) {
    return api.put("news/category/"+id, body).then(res => {
        return res && res.payload
    });
}
export function deleteCat(id) {
    return api.delete("news/category/"+id).then(res => {
        return res && res.payload
    });
}