import React, {useEffect, useState} from 'react';
import Title from "../../title/Title";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {
    BRANDS,
    LANG_UPPER,
    POSITION,
    TARIFF_LIMIT_TYPE,
    TARIFF_SERVICES_TYPE,
    VISIBILITY
} from "../../../constants/GLOBAL";
import {toast} from "react-toastify";
import {api} from "../../../api/api";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {GrClose} from "react-icons/all";

function AddTariff(props) {
    const {id} = useParams();
    const isAdd = id === "add";
    const history = useHistory();
    const [formData, setFormData] = useState();
    const [region, setRegion] = useState(null);
    const [file, setFile] = useState(null);

    const [data, setData] = useState({
        // categoryId: 1,
        companyNameEnum: "",
        dayTimeSpeed: "",
        description: "",
        name: "",
        nightSpeed: "",
        media: "",
        price: "",
        tariffLimitEnum: null,
        tariffServiceTypeEnum: null,
        tariffVisibilityEnum: "",
        tasIxSpeed: "",
        dayTime: "",
        nightTime: "",
        lang: "",
        regionId: [],
        type: ""
    });


    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleRegionChange = (name, value) => {
        let id = Number(value.label) + 1;
        let list = data?.regionId;
        list = removeItemIfExistElseAdd(list, id);
        setData({
            ...data,
            [name]: list
        });
    };

    const removeItemIfExistElseAdd = (list, number) => {
        let response = [];
        list.forEach((item) => {
            if (Number(item) !== Number(number)) {
                response.push(item)
            }
        });
        if (response.length >= list.length) {
            response.push(number)
        }
        return response;
    };

    useEffect(() => {
        let formData = new FormData();
        formData.append("file", file);
        setFormData(formData)
    }, [file]);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (id !== "add") {
                api.get("tariff/" + id).then(res => {
                    if (res && res.payload) {
                        setData(res.payload);
                    }
                })
            }
        }
        return () => mounted = false;
    }, [id]);

    useEffect(() => {
        api.get("tariff/region").then(res => {
            if (res && res.payload) {
                setRegion(res.payload);
            }
        })
    }, []);


    const handleSubmit = () => {
        if (!(
            Boolean(data.name) &&
            Boolean(data.lang) &&
            Boolean(data.companyNameEnum) &&
            Boolean(data.type) &&
            Boolean(data.price)
        )) {
            toast.error("Некоторые поля должны быть заполнены. Имя тарифа, Цена, Язык, Название бренда, Расположение, Видимость", {
                position: toast.POSITION.TOP_RIGHT
            });
            if ((data.type === POSITION.banner || data.type === POSITION.xit) && !data.media) {
                toast.error("Изображение должно быть загружено", {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } else {
            if (isAdd) {

                let query = {
                    "jsonString": JSON.stringify(data)
                };
                let queryUrl = new URLSearchParams(query);
                api.post("tariff?" + queryUrl, formData)
                    .then(() => {
                        toast.success("Успешно добавлено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        history.push("/tariff/all")
                    })
                    .catch(error => {
                        toast.error(error.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
            } else {
                let query = {
                    "json": JSON.stringify(data)
                };
                let queryUrl = new URLSearchParams(query);
                api.put("tariff/update?" + queryUrl, formData)
                    .then(() => {
                        toast.success("Успешно изменено", {
                            position: toast.POSITION.TOP_RIGHT
                        });
                        history.push("/tariff/all");
                    })
                    .catch(error => {
                        toast.error(error.message, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    })
            }
        }
    };
    return (
        <div>
            <Title
                title={isAdd ? "Добавить новое" : "Изменить"}
                base={props.base}
            />
            <div className="row">
                <div className="col-lg-9 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.name}
                                placeholder="Имя тарифа"
                                onChange={(e) => handleChange("name", e.target.value)}
                            />
                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.price}
                                placeholder="Цена"
                                onChange={(e) => handleChange("price", e.target.value)}
                            />
                            <textarea
                                className="form-control form-control-lg mb-3"
                                value={data.description}
                                placeholder="Краткое описание"
                                onChange={(e) => handleChange("description", e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="card card-small mb-3">
                        <div className="card-body">
                            {/*<input*/}
                            {/*    className="form-control form-control-lg mb-3"*/}
                            {/*    type="text"*/}
                            {/*    value={data.dayTimeSpeed}*/}
                            {/*    placeholder="Дневной скорость | MB/s"*/}
                            {/*    onChange={(e) => handleChange("dayTimeSpeed", e.target.value)}*/}
                            {/*/>*/}
                            {/*<input*/}
                            {/*    className="form-control form-control-lg mb-3"*/}
                            {/*    type="text"*/}
                            {/*    value={data.nightSpeed}*/}
                            {/*    placeholder="Ночной скорость | MB/s"*/}
                            {/*    onChange={(e) => handleChange("nightSpeed", e.target.value)}*/}
                            {/*/>*/}

                            <input
                                className="form-control form-control-lg mb-3"
                                type="text"
                                value={data.tasIxSpeed}
                                placeholder="Tas-IX скорость | MB/s"
                                onChange={(e) => handleChange("tasIxSpeed", e.target.value)}

                            />
                            <hr/>
                            <h3>Скорость и время</h3>
                            <label htmlFor="dayTime" className="w-100 mb-1">
                                <span className="mb-1 d-block">Дневное время</span>
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    id="dayTime"
                                    value={data?.dayTime}
                                    placeholder="от __:__ до __:__ - 00 Mb/s"
                                    onChange={(e) => handleChange("dayTime", e.target.value)}
                                />
                            </label>
                            <label htmlFor="nightTime" className="w-100 ">
                                <span className="mb-1 d-block">Ночное время</span>
                                <input
                                    className="form-control form-control-lg mb-3"
                                    type="text"
                                    id="nightTime"
                                    value={data?.nightTime}
                                    placeholder="от __:__ до __:__ - 00 Mb/s"
                                    onChange={(e) => handleChange("nightTime", e.target.value)}
                                />
                            </label>

                        </div>
                    </div>

                    <div className="card card-small mb-3">
                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    <div className="custom-control custom-radio mb-1">
                                        <input
                                            type="checkbox"
                                            id={"tariffs_brand_all"}
                                            name="companyNameEnum"
                                            className="custom-control-input"
                                            value={"all"}
                                            checked={data?.regionId?.length === region?.length}
                                            onChange={() => {
                                                let list = [];
                                                if (data?.regionId?.length < region?.length) {
                                                    region.forEach((item) => list.push(Number(item.id)));
                                                }
                                                setData({...data, "regionId": list})
                                            }}
                                        />
                                        <label
                                            className="custom-control-label text-capitalize"
                                            htmlFor={"tariffs_brand_all"}
                                        >Все</label>
                                    </div>
                                    {objectToArrayForSelect(region).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="checkbox"
                                                id={"tariffs_brand_" + item.value.id}
                                                name="companyNameEnum"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.regionId.includes(item.value.id)}
                                                onChange={() => handleRegionChange("regionId", item)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_brand_" + item.value.id}
                                            >{item.value.name}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {(
                        data.companyNameEnum === BRANDS.etc ||
                        data.type === POSITION.banner ||
                        data.type === POSITION.xit
                    ) && (
                        <div className="card card-small mb-3">
                            <div className="card-body">
                                {!isAdd && data && data.media && (

                                    <div className="mb-4">
                                        {data.media.includes(".mp4") ? (
                                            <video width="320" height="240" controls>
                                                <source src={data.media} type="video/mp4"/>
                                            </video>
                                        ) : (
                                            <img src={data.media} alt=""/>
                                        )}

                                    </div>
                                )}
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="form-control form-control-lg mb-3"
                                            type="file"
                                            id="tariff_logo__input"
                                            accept=".jpg , .png , .mp4"
                                            onChange={(e) => setFile(e.target.files[0])}
                                        />
                                        <label
                                            htmlFor="tariff_logo__input"
                                            className={"tariff_logo__label " + (file ? "bg-success" : "bg-primary")}
                                        >
                                            {file ? file?.name : "Выберите медиа"}
                                        </label>
                                    </div>
                                    {file && <button onClick={() => setFile(null)}
                                                     className="btn btn-danger p-2 file-remove-btn"><GrClose/></button>}
                                </div>
                                <p className="mb-1 font-weight-normal">Максималыный размер медиа 5mb. <br/> Тип <b>.png,
                                    .jpg, .mp4</b> поддерживает.</p>
                                {file && <div>
                                    <p className="mb-2">
                                        Размер: <span
                                        className="text-primary">{(file?.size / 1e+6).toFixed(2)} MB </span>
                                    </p>
                                </div>}
                            </div>
                        </div>
                    )}

                </div>

                <div className="col-lg-3 col-md-12">
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Язык</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(LANG_UPPER).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"tariffs_lang_" + item.value}
                                                name="lang"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.lang === item.value}
                                                onChange={(e) => handleChange("lang", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_lang_" + item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Название бренда</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(BRANDS).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"tariffs_brand_" + item.value}
                                                name="companyNameEnum"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.companyNameEnum === item.value}
                                                onChange={(e) => handleChange("companyNameEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_brand_" + item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Расположение на странице</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(POSITION).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"tariffs_type_" + item.value}
                                                name="type"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.type === item.value}
                                                onChange={(e) => handleChange("type", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_type_" + item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Видимость тарифов</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(VISIBILITY).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"tariffs_visibility_" + item.value}
                                                name="visibility"
                                                className="custom-control-input"
                                                value={item.value}
                                                checked={data.tariffVisibilityEnum === item.value}
                                                onChange={(e) => handleChange("tariffVisibilityEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_visibility_" + item.value}
                                            >{item.label}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>
                    {(data.companyNameEnum === BRANDS.tps && data.tariffVisibilityEnum === VISIBILITY.corporate) &&
                    <div className="card card-small mb-3">
                        <div className="card-header border-bottom">
                            <h6 className="m-0">Виды тарифов</h6>
                        </div>
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item px-3 pb-2">
                                    {objectToArrayForSelect(TARIFF_LIMIT_TYPE).map((item, key) => (
                                        <div key={key} className="custom-control custom-radio mb-1">
                                            <input
                                                type="radio"
                                                id={"tariffs_limit_" + item.value.code}
                                                name="tariffLimitEnum"
                                                className="custom-control-input"
                                                value={item.value.code}
                                                checked={data.tariffLimitEnum === item.value.code}
                                                onChange={(e) => handleChange("tariffLimitEnum", e.target.value)}
                                            />
                                            <label
                                                className="custom-control-label text-capitalize"
                                                htmlFor={"tariffs_limit_" + item.value.code}
                                            >{item.value.title}</label>
                                        </div>
                                    ))}
                                </li>
                            </ul>
                        </div>
                    </div>}
                    {(data.companyNameEnum === BRANDS.tps && data.tariffVisibilityEnum === VISIBILITY.public) && (
                        <div className="card card-small mb-3">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Виды тарифов</h6>
                            </div>
                            <div className="card-body p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-3 pb-2">
                                        {objectToArrayForSelect(TARIFF_SERVICES_TYPE).map((item, key) => (
                                            <div key={key} className="custom-control custom-radio mb-1">
                                                <input
                                                    type="radio"
                                                    id={"tariffs_services_" + item.value}
                                                    name="tariff_service"
                                                    className="custom-control-input"
                                                    value={item.value}
                                                    checked={data.tariffServiceTypeEnum === item.value}
                                                    onChange={(e) => handleChange("tariffServiceTypeEnum", e.target.value)}
                                                />
                                                <label
                                                    className="custom-control-label text-capitalize"
                                                    htmlFor={"tariffs_services_" + item.value}
                                                >{item.label}</label>
                                            </div>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        </div>)}
                    <div className="card card-small mb-3">
                        <div className="card-body p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex px-3">
                                    <NavLink to="/tariff/all" className="btn btn-outline-accent">
                                        Отмена
                                    </NavLink>
                                    <button className="btn btn-success ml-auto" onClick={handleSubmit}>
                                        Публиковать
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/*<BlockLoader/>*/}
        </div>
    );
}

export default AddTariff;










