import React, {useEffect, useState} from "react";
import Title from "../../title/Title";
import {api} from "../../../api/api";
import {LANG_UPPER, VISIBILITY_FOR_NEWS, BRANDS} from "../../../constants/GLOBAL";
import {objectToUrl, objectToArrayForSelect, findInObject} from "../../../utils/helpers";
import Select from "../../ui/select/Select";
import ROUTES from "../../../constants/ROUTES";
import TariffItem from "./components/list/TariffItem";
import {toast} from "react-toastify";
import BlockLoader from "../../ui/loader/BlockLoader";

const TariffList = (props) => {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.uz,
        companyNameEnum: BRANDS.tps,
        tariffVisibilityEnum: VISIBILITY_FOR_NEWS.home
    })
    const [tariffs, setTariffs] = useState(null);
    const [cats, setCats] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reRender, setRender] = useState(false);

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setLoading(true);
            api.get("tariff/list" + objectToUrl(filter)).then(res => {
                if (res && res.payload) {
                    setLoading(false);
                    setTariffs(res.payload)
                }
            });
            api.get("tariff/category/?lang=" + filter.lang).then(res => {
                if (res && res.payload) {
                    setCats(res.payload)
                }
            })

        }
        return () => mounted = false;
    }, [filter, reRender]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
        setTariffs(null)
    }


    const handleDelete = (id) => {
        setLoading(true)
        api.delete("tariff/" + id).then(res => {
            setRender(!reRender);
            toast.success("Удалено");
        })
    }


    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.tariff_add}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_brand"
                                extraClassForSelect="mini"
                                label="Brand"
                                values={objectToArrayForSelect(BRANDS)}
                                filterName="companyNameEnum"
                                activeValue={filter.companyNameEnum}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="news_visibility"
                                extraClassForSelect="mini"
                                label="Visibility"
                                values={objectToArrayForSelect(VISIBILITY_FOR_NEWS)}
                                filterName="tariffVisibilityEnum"
                                activeValue={filter.tariffVisibilityEnum}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            {loading ? <BlockLoader/> : (
                <>
                    <div className="row">
                        {tariffs && cats && tariffs.map((item, key) => (
                            <TariffItem
                                key={key}
                                id={item.id}
                                name={item.name}
                                price={item.price}
                                category={item.categoryId && findInObject(cats, "id", item.categoryId, "name")}
                                dayTimeSpeed={item.dayTimeSpeed}
                                nightSpeed={item.nightSpeed}
                                tasIxSpeed={item.tasIxSpeed}
                                nightTime={item?.nightTime}
                                dayTime={item?.dayTime}
                                tariffServiceTypeEnum={item.tariffServiceTypeEnum}
                                type={item.type}
                                deleteItem={handleDelete}
                            />
                        ))}
                    </div>
                    {tariffs && tariffs.length === 0 && (
                        <div className="font-weight-bold"><h4>Пусто</h4></div>
                    )}
                </>
            )}
        </div>
    )
}

export default TariffList