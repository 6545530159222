import React, {useState} from "react";
import {api} from "../../api/api";
import Cookies from 'universal-cookie';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import PageLoader from "../ui/loader/PageLoader";
import {setLoading, setToken} from "../../store/main/mainActions";
import {useHistory} from 'react-router-dom'

export default function Login() {
    const cookies = new Cookies();
    const history = useHistory();
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.main.isLoading)
    const [data, setData] = useState({
        email: "",
        password: ""
    });


    const handleSubmit = (e) => {
        dispatch(setLoading(true));
        api.post("login", data).then(response => {
            cookies.set('token', response.payload.token);
            toast.success("Вход успешно завершен", {
                position: toast.POSITION.TOP_RIGHT
            });
            let role= response.payload.authorities.map(x => {
                return x.role
            }).join(",");

            history.replace("/");
            dispatch(setToken(response.payload.token));
            dispatch(setLoading(false));
            localStorage.setItem("role", role);
            localStorage.setItem("userId", response.payload.id);
            localStorage.setItem("userLogin", response.payload.email);

        })
        .catch(() => {
            dispatch(setLoading(false))
        })
    }

    const handleChange = (e) => {
        setData(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    return (
        <div className="login">
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{height: "100vh"}}>
                    <div className="col-sm-4 col-12">
                        <div className="card card-small">
                            <div className="card-header border-bottom">
                                <h6 className="m-0">Авторизоваться</h6>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item p-3">
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                value={data.email}
                                                onChange={(e) => handleChange(e)}
                                                id="email"
                                                className="form-control"
                                                placeholder="Логин"
                                            />
                                            <span style={{display: "none"}}/>
                                            <span style={{display: "none"}}/>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className="form-control"
                                                onChange={(e) => handleChange(e)}
                                                id="password"
                                                value={data.password}
                                                placeholder="Пароль"
                                            />
                                        </div>
                                        <div className="form-group mb-0">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                disabled={!data.email || !data.password}
                                                onClick={handleSubmit}
                                            >Логин
                                            </button>
                                        </div>
                                    </form>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <PageLoader/>}
        </div>
    )
}