export const LANG = {
  uz: "uz",
  ru: "ru",
  en: "en"
}

export const BRANDS = {
  tps: "TPS",
  evo: "EVO",
  etc: "ETC"
};


export const POSITION = {
  banner: "BANNER",
  xit: "XIT",
  top: "TOP",
  simple: "SIMPLE"
}

export const VISIBILITY = {
  public: "HOME",
  corporate: "CORPORATE",
}

export const VISIBILITY_PC = {
  public: "PUBLIC",
  corporate: "CORPORATE",
}



export const BRANDS_FOR_TARIFF = {
  tps: "TPS",
  evo: "EVO",
  etc: "ET"
}

export const LANG_UPPER = {
  uz: "UZ",
  ru: "RU",
  en: "EN"
}

export const FAQ_CATEGORY = {
  physically: "PHYSICALLY",
  juridically: "JURIDICALLY",
}


export const VISIBILITY_FOR_NEWS = {
  home: "HOME",
  business: "BUSINESS",
  corporate: "CORPORATE",
}

export const TARIFF_SERVICES_TYPE = {
  adsl: "ADSL",
  fttb: "FTTB"
}

export const TARIFF_LIMIT_TYPE = {
  Limited: {title:"Лимитные", code:"LIMITED_TARIFF"},
  Day_Time_Unlimited: {title:"Дневные безлимитные", code:"DAYTIME_UNLIMITED"},
  Unlimited: {title:"Безлимитные", code:"UNLIMITED_TARIFF"},
  Unlimited_TasIx: {title:"Безлимитный Tas-IX ", code:"UNLIMITED_TASIX"}
}