import Title from "../../title/Title";
import React, {useEffect, useState} from "react";
import {api} from "../../../api/api";
import BannerItem from "./BannerItem";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import Select from "../../ui/select/Select";

const Banners = (props) => {

    const [filter, setFilter] = useState({
        lang: LANG_UPPER.ru,
        type: "BANNER",
        companyName: BRANDS.tps
    });

    useEffect(()=>{
        let mounted = true;
        if(mounted){
            api.get("/banner/list/"+ objectToUrl(filter)).then(res => {
                if (res && res.payload) {
                    setData(res.payload)
                }
            })
        }
        return () => mounted = false;
    }, [filter]);

    const [data, setData] = useState([]);

    //
    useEffect(()=>{
        let mounted = true;
        if(mounted){
            api.get("/banner/list/" + objectToUrl(filter)).then(res => {
                if (res && res.payload) {
                    setData(res.payload)
                }
            })
        }
        return () => mounted = false;
    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setData(null)
    };

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="faq_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="banner_brand"
                                extraClassForSelect="mini"
                                label="Brand"
                                values={objectToArrayForSelect(BRANDS)}
                                filterName="companyName"
                                activeValue={filter.companyName}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>

            {data && data.map(item => {
                let keys = Object.keys(item)
                let isTariff = false
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i] === "dayTime") {
                        isTariff = true
                    }
                }

                console.log(isTariff)

                if (isTariff) {
                    return (
                        <BannerItem title={item.name} desc={item.description} media={item.media} id={item.id}  isTariff={isTariff}/>
                    )
                }
                else {
                    return (
                        <BannerItem title={item.title} id={item.id} desc={item.shortDescription} media={item.imageStorageList[0]} isTariff={isTariff}/>
                    )
                }

            })}
            <div>
                {data && data.length === 0 && (
                    <div className="font-weight-bold"><h4>Пусто</h4></div>
                )}
            </div>

        </div>
    )
}

export default Banners