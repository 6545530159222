import Title from "../../title/Title";
import React, {useEffect, useState} from "react";
import ZoneCoverDistricts from "./ZoneCoverDistricts";
import {addCity, getCities, editCity, deleteCity} from "./actions/ZoneCoverActions";
import {toast} from "react-toastify";
import BlockLoader from "../../ui/loader/BlockLoader";
import {BsChevronDown, BsChevronRight, GrAddCircle, FiEdit2, AiOutlineDelete} from "react-icons/all";
import {alphabetSort} from "../../../utils/helpers";

const modeEdit = 'modeEdit'

const ZoneCover = (props) => {
    const [list, setList] = useState();
    const [active, setActive] = useState();
    const [currentItem, setCurrentItem] = useState({
        name: "",
        id: null
    });
    const [reRender, setRender] = useState(false)
    const [loading, setLoading] = useState(true)
    const [mode, setMode] = useState(false)

    useEffect(() => {
        let mounted = true;
        if(mounted){
            setLoading(true)
            getCities().then(res => {
                setLoading(false)
                if(res && res.length){
                    setList(alphabetSort(res, "name"))
                }
            }).catch(() => {
                setLoading(false)
            })
        }
        return () => mounted = false;
    }, [reRender])



    const handleChange = (name, value) => {
        setCurrentItem(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const handleCreate = (e) => {
        e.preventDefault()
        setLoading(true)
        addCity(currentItem.name).then(()=>{
            toast.success("Успешно добавлено", {
                position: toast.POSITION.TOP_RIGHT
            });
            setRender(!reRender)
            handleChange("name", "")
        })
    }


    const handleEdit = (e) => {
        e.preventDefault()
        setLoading(true)
        editCity(currentItem.name, currentItem.id).then(()=>{
            toast.success("Успешно изменено", {
                position: toast.POSITION.TOP_RIGHT
            });
            setRender(!reRender)
            setMode(false)
            handleChange("name", "")
            handleChange("id", null)
        })
    }

    const onClickEdit = (value, id) => {
        handleChange("name", value)
        handleChange("id", id)
        setMode(modeEdit + "_" + id)
    }

    const onClickDelete = (id) => {
        deleteCity(id).then(()=>{
            toast.success("Успешно удалено", {
                position: toast.POSITION.TOP_RIGHT
            });
            setRender(!reRender)
        })
    }

    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
            />
                <div className="zonecover">
                    {loading ? <BlockLoader/> : (
                        <>
                            {list && list.map((item, key) => {
                                return (
                                    <div className="zonecover__item" key={key}>
                                        <div className="zonecover__title" onClick={() => active !== item.id && setActive(item.id)}>
                                            <div className="d-flex align-items-center">
                                                <span className="before-icon">
                                                    {active === item.id ? <BsChevronDown onClick={() => active === item.id && setActive(null)}/> : <BsChevronRight/>}
                                                </span>
                                                {mode === (modeEdit+"_"+item.id) ? (
                                                    <form className="d-flex align-items-center" onSubmit={(e) => handleEdit(e)}>
                                                        <input
                                                            className="form-control form-control mr-2"
                                                            type="text"
                                                            value={currentItem.name}
                                                            placeholder="Название"
                                                            onChange={(e)=>handleChange("name", e.target.value)}
                                                            required
                                                        />
                                                        <button className="btn btn-success">
                                                            Изменить
                                                        </button>
                                                    </form>
                                                ) : item.name}
                                            </div>
                                            <span className="info-msg">Город</span>
                                            <div className="zonecover__actions">
                                                <button className="btn btn-success mr-2 p-2" onClick={() => onClickEdit(item.name, item.id)}>
                                                    <FiEdit2/>
                                                </button>
                                                <button className="btn btn-danger p-2" onClick={() => onClickDelete(item.id)}>
                                                    <AiOutlineDelete/>
                                                </button>
                                            </div>
                                        </div>
                                       {active === item.id && <div className={"zonecover__content " + (active === item.id ? "open" : "closed")}>
                                            <ZoneCoverDistricts id={item.id}/>
                                        </div>}
                                    </div>
                                )
                            })}
                        </>
                    )}
                    <div className="zonecover__item">
                        <div className="zonecover__title zonecover__add__title" onClick={() => setActive(active === "add_new" ? null : "add_new" )}>
                            <div>
                                <span className="before-icon white-icon">
                                    <GrAddCircle/>
                                </span>
                                Добавить новый
                            </div>
                            <span className="info-msg">Город</span>
                        </div>
                        <div className={"zonecover__add " + (active === "add_new" ? "open" : "closed")}>
                            <div className="add_form">
                                <form onSubmit={(e) => handleCreate(e)}>
                                    <div className="row">
                                        <div className="col-md-10">

                                            <input
                                                className="form-control form-control"
                                                type="text"
                                                value={currentItem.name}
                                                placeholder="Название"
                                                onChange={(e)=>handleChange("name", e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <button className="btn btn-success">
                                                Добавить
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ZoneCover