import Title from "../../title/Title";
import React, {useEffect, useState} from "react";
import {api} from "../../../api/api";
import ROUTES from "../../../constants/ROUTES";
import BlockLoader from "../../ui/loader/BlockLoader";
import SupportCategoriesItem from "./SupportCategorieItem"

const SupportCategoriesList = (props) => {



  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);


  useEffect(()=>{
    let mounted = true;
    if(mounted){
      setLoading(true)
      api.get("/support/category/list").then(res => {
        if (res && res.payload) {
          setLoading(false)
            setData(res.payload)
        }
      })
    }
    return () => mounted = false;
  }, []);

  return (
    <div>
      <Title
        title={props.title}
        base={props.base}
        addNewButtonUrl={ROUTES.support_categories_add}
      >
      </Title>
      <div>
          {
              data && data.map(item => {
                  return (
                      <SupportCategoriesItem  title={item.title} link={item.link} id={item.id} key={item.id}/>
                  )
              })
          }
      </div>
      {isLoading && (
          <BlockLoader/>
      )}
      <div>
        {data && data.length === 0 && (
            <div className="font-weight-bold"><h4>Пусто</h4></div>
        )}
      </div>

    </div>
  )
}

export default SupportCategoriesList