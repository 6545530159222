import React, {useState} from 'react';
import { Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {AiOutlineDelete, FiEdit2} from "react-icons/all";
import {api} from "../../../../api/api";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";

export default function ContactsItem({data, index}) {
    const [deleted, setDeleted] = useState();
    const handleDelete = (id) => {
        api.delete("company-contact/delete/"+id).then(res=>{
            if(res.status) {
                toast.success("Удалено");
                setDeleted(true);
            }
        })
    }

    return (
                <Tr className={`contacts_item ${deleted && "deleted"}`}>
                    <Th scope="row">{index + 1}</Th>
                    <Td>{data?.email}</Td>
                    <Td>{data?.phoneNumber}</Td>
                    <Td>{data?.country}</Td>
                    <Td>{data?.city}</Td>
                    <Td>{data?.region}</Td>
                    <Td>у. {data?.street}, дом {data?.building}</Td>
                    <Td>
                        <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2" to={"/contacts/item/"+data?.id}>
                            <FiEdit2 className="mr-1"/>
                            Изменить
                        </NavLink>
                    </Td>
                    <Td>
                        <button onClick={()=> handleDelete(data?.id)} className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase" >
                            <AiOutlineDelete className="mr-1"/>
                            Delete
                        </button>
                    </Td>
                </Tr>

    );
}

