import React from "react";
import {NavLink} from 'react-router-dom'

const MenuItem = (props) => {
  return(
    <li className="nav-item">
      <NavLink
          className="nav-link"
          to={props.link}
          activeClassName="active"
      >
        <div>
          {props.icon}
          <span>{props.title}</span>
        </div>
      </NavLink>
    </li>
  )
}
export default MenuItem