import React, {useState} from "react";
import {AiOutlineDelete, BsChevronDown, BsChevronRight, FiEdit2} from "react-icons/all";
import {api} from "../../../api/api";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";

const PublicOfferItem = (props) => {
    const [deleted, setDeleted] = useState();
    const handleDelete = (id) => {
        api.delete("public-offer/delete/" + id)
            .then(response => {
                if (response.status) {
                    toast.success("Удалено");
                    setDeleted(true);
                }
            })
            .catch(error => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })
    };
    return (
        <div className={`list-group-item py-3 ${deleted && "deleted"}`}>
            <div className="list-group-title"
                 onClick={() => props.onChangeTab(props.tab === props.data.title.id ? null : props.data.title.id)}>
                <span className="d-inline-block mr-1">
                    {props.tab === props.data.title.id ? <BsChevronDown/> : <BsChevronRight/>}
                </span>
                {props.data.title.title}
            </div>
            <div className="list-group-actions">
                {props.tab === props.data.title.id && (
                    <div className="body mt-2 pl-4">
                        <hr className="mt-1"/>
                        {props.data.offerList && props.data.offerList.length ? props.data.offerList.map((item, i) => (
                            <p key={i}>{item.content}</p>
                        )) : <div className="font-weight-bold"><h4>Пусто</h4></div>}
                        <div className="my-auto ml-auto d-flex justify-content-between">
                            <NavLink className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"
                                     to={"/public-offer/" + props.data?.title.id}>
                                <FiEdit2 className="mr-1"/>
                                Edit
                            </NavLink>
                            <button onClick={() => handleDelete(props.data?.title.id)}
                                    className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase">
                                <AiOutlineDelete className="mr-1"/>
                                Delete
                            </button>
                        </div>
                    </div>
                )}
                {/*<button*/}
                {/*    className="btn btn-sm btn-success pl-2 pr-2 text-uppercase mr-2"*/}
                {/*    onClick={() => props.onEdit({*/}
                {/*        id: props.id,*/}
                {/*        name: props.title*/}
                {/*    })}*/}
                {/*>*/}
                {/*    <FiEdit2 className="mr-1"/>*/}
                {/*    Изменить*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="btn btn-sm btn-danger pl-2 pr-2 text-uppercase"*/}
                {/*    onClick={() => props.onDelete({*/}
                {/*        id: props.id,*/}
                {/*        name: props.title*/}
                {/*    })}*/}
                {/*>*/}
                {/*    <AiOutlineDelete className="mr-1"/>*/}
                {/*    Удалить*/}
                {/*</button>*/}

            </div>
        </div>
    )
};

export default PublicOfferItem
