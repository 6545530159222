import {BrowserRouter} from 'react-router-dom';

import {Provider} from 'react-redux';
import store from "./store/store";

import './assets/styles/bootstrap.min.css';
import './assets/styles/extras.1.1.0.min.css';
import './assets/styles/shards-dashboards.1.1.0.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles.css';


import Main from "./components/views/Main";

function App() {

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Main/>
            </BrowserRouter>
        </Provider>
    )
}

export default App;
