import React, {useEffect, useState} from "react";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {api} from "../../../api/api";
import {objectToArrayForSelect, objectToUrl} from "../../../utils/helpers";
import {toast} from "react-toastify";
import Title from "../../title/Title";
import ROUTES from "../../../constants/ROUTES";
import Select from "../../ui/select/Select";
import BlockLoader from "../../ui/loader/BlockLoader";
import CompanyRouterItem from "./CompanyRouterItem";

export default function CompanyRouterList(props) {
    const [filter, setFilter] = useState({
        lang: LANG_UPPER.en,
        companyName: BRANDS.etc
    });
    const [companyRouter, setCompanyRouter] = useState();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setLoading(true);
            api.get("company-router/list" + objectToUrl(filter))
                .then(res => {
                    if (res && res.payload) {
                        setLoading(false);
                        setCompanyRouter(res.payload)
                    }
                })
                .catch(error => {
                    setLoading(false);
                    toast.error(error?.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }, [filter]);

    const handleFilterChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        });
        setCompanyRouter(null)
    };


    return (
        <div>
            <Title
                title={props.title}
                base={props.base}
                addNewButtonUrl={ROUTES.company_router_add}
            >
                <form>
                    <div className="form-row">
                        <div className="form-group mr-3 mb-0">
                            <Select
                                id="companyRouter_language"
                                extraClassForSelect="mini"
                                label="Language"
                                values={objectToArrayForSelect(LANG_UPPER)}
                                filterName="lang"
                                activeValue={filter.lang}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </div>
                </form>
            </Title>
            <div>
                {companyRouter && companyRouter.map((item, key) => (
                    <CompanyRouterItem
                        key={key + "_router"}
                        data={item}
                    />
                ))}
            </div>
            {isLoading && (
                <BlockLoader/>
            )}
            <div>
                {companyRouter && companyRouter.length === 0 && (
                    <div className="font-weight-bold"><h4>Пусто</h4></div>
                )}
            </div>
        </div>
    )
}
