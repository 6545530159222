import React, {useEffect, useState} from "react";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {BRANDS, LANG_UPPER} from "../../../constants/GLOBAL";
import {toast} from "react-toastify";
import Title from "../../title/Title";
import {objectToArrayForSelect} from "../../../utils/helpers";
import {editContacts, getContacts} from "./contactActions";


export function EditContacts(props) {
    const {id} = useParams();
    const isAdd = id === "add";
    const [data, setData] = useState(
        {
            building: "",
            city: "",
            companyName: "",
            country: "Узбекистан",
            email: "",
            lang: "",
            phoneNumber: "",
            region: "",
            street: ""
        }
    );
    const handleChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        })
    };
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (id !== "add") {
                getContacts(id).then(res => {
                    setData(res);
                })
            }
        }
        return () => mounted = false;
    }, [id]);

    const history = useHistory();
    const handleSubmit = () => {
        if (!(
            Boolean(data.email) &&
            Boolean(data.phoneNumber) &&
            Boolean(data.region) &&
            Boolean(data.street) &&
            Boolean(data.building) &&
            Boolean(data.city)
        )) {
            // setValidate(true);
            toast.error("Некоторые поля должны быть заполнены", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            if (!isAdd) {
                editContacts(data).then(res => {
                    history.push("/contacts");
                    toast.success("Успешно изменен", {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        }
    };

return (
    <div>
        <Title title={isAdd ? "Добавить новое" : "Изменить"} base={props.base}>
        </Title>
        <div className="row">
            <div className="col-lg-9 col-md-12">
                <div className="card card-small mb-3">
                    <div className="card-body">
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.email}
                            placeholder="Э-почта"
                            onChange={(e) => handleChange("email", e.target.value)}
                        />
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.phoneNumber}
                            placeholder="Телефон номер"
                            onChange={(e) => handleChange("phoneNumber", e.target.value)}
                        />
                    </div>
                    <div>
                    </div>
                </div>
                <div className="card card-small mb-3">
                    <div className="pl-3 pt-3">
                        <h4>Адресс</h4>
                    </div>
                    <div className="card-body">
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.city}
                            placeholder="Город"
                            onChange={(e) => handleChange("city", e.target.value)}
                        />
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.region}
                            placeholder="Область"
                            onChange={(e) => handleChange("region", e.target.value)}
                        />
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.street}
                            placeholder="Улица"
                            onChange={(e) => handleChange("street", e.target.value)}
                        />
                        <input
                            className="form-control form-control-lg mb-3"
                            type="text"
                            value={data.building}
                            placeholder="Дом"
                            onChange={(e) => handleChange("building", e.target.value)}
                        />
                    </div>
                    <div>
                    </div>
                </div>
            </div>


            <div className="col-lg-3 col-md-12">
                <div className="card card-small mb-3">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Язык</h6>
                    </div>
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item px-3 pb-2">
                                {objectToArrayForSelect(LANG_UPPER).map((item, key) => (
                                    <div key={key} className="custom-control custom-radio mb-1">
                                        <input
                                            type="radio"
                                            id={"contacts_lang_" + item.value}
                                            name="lang"
                                            className="custom-control-input"
                                            value={item.value}
                                            checked={data.lang === item.value}
                                            onChange={(e) => handleChange("lang", e.target.value)}
                                        />
                                        <label
                                            className="custom-control-label text-capitalize"
                                            htmlFor={"contacts_lang_" + item.value}
                                        >{item.label}</label>
                                    </div>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card card-small mb-3">
                    <div className="card-header border-bottom">
                        <h6 className="m-0">Название бренда</h6>
                    </div>
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item px-3 pb-2">
                                {objectToArrayForSelect(BRANDS).map((item, key) => (
                                    <div key={key} className="custom-control custom-radio mb-1">
                                        <input
                                            type="radio"
                                            id={"contacts_brand_" + item.value}
                                            name="companyName"
                                            className="custom-control-input"
                                            value={item.value}
                                            checked={data.companyName === item.value}
                                            onChange={(e) => handleChange("companyName", e.target.value)}
                                        />
                                        <label
                                            className="custom-control-label text-capitalize"
                                            htmlFor={"contacts_brand_" + item.value}
                                        >{item.label}</label>
                                    </div>
                                ))}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card card-small mb-3">
                    <div className="card-body p-0">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex px-3">
                                <NavLink to="/contacts" className="btn btn-outline-accent">
                                    Отмена
                                </NavLink>
                                <button onClick={handleSubmit} className="btn btn-success ml-auto">
                                    Публиковать
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
)
}