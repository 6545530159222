import React from "react";
import Menu from './menu/Menu'

const Sidebar = (props) => {
  return(
    <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0">
      <div className="main-navbar">
        <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <div className="navbar-brand w-100 mr-0" style={{lineHeight: "25px"}}>
            <div className="d-table m-auto">
              <span className="d-none d-md-inline ml-1">Admin Panel</span>
            </div>
          </div>
        </nav>
      </div>
      <div className="nav-wrapper">
        <Menu/>
      </div>
    </aside>
  )
}

export default Sidebar